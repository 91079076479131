import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-http-backend';

i18n.use(LanguageDetector)
    .use(backend)
    .use(initReactI18next)
    .init({
        ns: [],
        defaultNS: 'global',
        supportedLngs: ['en', 'es', 'fr', 'it', 'de', 'pt', 'nl', 'dk', 'pl'],
        fallbackLng: 'en', // use en if detected lng is not available
        detection: {
            order: ['path', 'navigator'],
            lookupFromPathIndex: 0,
        },
        keySeparator: false, // we do not use keys in form messages.welcome
        returnObjects: true,
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
