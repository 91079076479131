import i18next from 'i18next';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/useRedux';
import { setIsInUS } from '../../../redux/bookingSlice';

const CountryRedirect = () => {
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const pathnameWithoutCountry = pathname.replace('/us', '/en');
    dispatch(setIsInUS(true));
    i18next.changeLanguage('en');

    return <Navigate replace to={pathnameWithoutCountry} />;
};

export default CountryRedirect;
