import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { DateTime } from 'luxon';
import { FromTypes } from '@/components/funnel/payment/Payment';
import { useAppSelector } from './useRedux';
import APIKit from '@/utils/APIKit';
import usePrices from './usePrices';

interface RakutenPurchaseNotification {
    from: FromTypes;
    transactionId: string | number;
}

const useRakuten = () => {
    const [cookies, setCookie] = useCookies(['ranSiteID', 'ranSiteID_setTime', 'cookieyes-consent']);
    const planId = useAppSelector((state) => state.plan.currentPlan);
    const { origin, from: departureDate, to: arrivalDate } = useAppSelector((state) => state.booking);
    const currencyName = useAppSelector((state) => state.booking.currency?.name);
    const totalPrice = useAppSelector((state) => state.booking.totalPrice);
    const { discountCode, discountType } = useAppSelector((state) => state.discount);
    const { discountQuantity } = usePrices();
    const isProductionEnv = import.meta.env.VITE_APP_ENV === 'prod';
    const isAmplifyDevEnv = import.meta.env.VITE_APP_ENV === 'dev';
    const location = useLocation();

    // Set this to false if you want to test the Rakuten tracking in local environment
    /* const isLocalEnv = false; */
    const isLocalEnv = !isProductionEnv && !isAmplifyDevEnv;

    const currentTime = DateTime.utc().toFormat('yyyyMMdd_HHmm');
    const queryParams = new URLSearchParams(location.search);
    const rakutenRanSiteID = cookies?.ranSiteID;
    const rakutenCookieSetTime = cookies?.ranSiteID_setTime;

    const setRakutenCookie = () => {
        const rakutenQueryParam = queryParams.get('ranSiteID');
        const oneYearFromNow = DateTime.utc().plus({ years: 1 }).toJSDate();

        if (rakutenQueryParam) {
            setCookie('ranSiteID', rakutenQueryParam, { expires: oneYearFromNow, domain: '.flykube.com' });
            setCookie('ranSiteID_setTime', currentTime, { expires: oneYearFromNow, domain: '.flykube.com' });
        }
    };

    const getProductName = (from: FromTypes) => {
        const isFromGiftCard = from === 'giftCard';
        const isFromGiftTravel = from === 'giftTravel';

        if (isFromGiftCard) return 'Gift Card';
        if (isFromGiftTravel) return `Gift Travel: Plan ${planId}`;
        return `Booking: Plan ${planId}`;
    };

    const formattedDepartureDate = DateTime.fromISO(departureDate).toFormat('yyyyMMdd_HHmm');
    const formattedReturnDate = DateTime.fromISO(arrivalDate).toFormat('yyyyMMdd_HHmm');

    const notifyRakutenPurchase = ({ from, transactionId }: RakutenPurchaseNotification) => {
        if (!rakutenRanSiteID || isLocalEnv) return;

        const isFromBooking = from === 'booking';

        const RAKUTEN_MERCHANT_ID = 52774;

        APIKit.post('rakuten/', {
            // A static numeric merchant ID (to identify you in the Rakuten Marketing system) provided to you by Rakuten Marketing
            mid: RAKUTEN_MERCHANT_ID,

            // This is a unique transaction orderID composed of 1 to 40 non-blank characters.
            ord: transactionId,

            // The ranSiteID value that was set in a cookie at your Rakuten Marketing specific gateway page. Length is 34 characters.
            tr: rakutenRanSiteID,

            // yyyymmdd_hhmm in GMT 24-hour format. This is the date-time value that is set in a cookie at your Rakuten Marketing gateway page when the customer arrives at your site.
            land: rakutenCookieSetTime,

            // yyyymmdd_hhmm in GMT 24-hour format. This date-time value represents the date and time of the online completion (thank you page).
            date: currentTime,

            // This is the amount value in form of (price * quantity * 100). A sale of $3.25 is output as 325. GBP example: a total sale of £3.50 is output as 350.
            amtlist: totalPrice * 100,

            // This is a unique product identifier. When several different products are included in a single order, distinct SKUs should be pipe-delimited using |.
            skulist: isFromBooking ? 'booking' : 'gift',

            // This is a pipe-delimited list of quantity values. The order of values in this list must match the order of values in the skulist.
            qlist: 1,

            // Alphanumeric 3 characters. Use (‘USD’, ‘CAD’, ‘GBP’, ‘JPY’, ‘BRL’, ‘EUR’, and ‘AUD’).
            cur: currencyName,

            // Pipe-delimited list of product name values. The order of values in this list must match the order of values in the skulist.
            namelist: getProductName(from),

            // Date when the item which was a booking was used by the consumer.
            booking_consumed_date: isFromBooking ? formattedDepartureDate : undefined,

            // Name of the site where the consumer makes the purchase.
            converting_site_name: 'flykube',

            // The coupon code applied to an order.
            coupon_order: discountCode || undefined,

            // An advertiser specified unique ID for the customer that made the order.
            customer_id: '',

            // The Advertising ID of the mobile device.
            device_id: navigator?.userAgent ?? '',

            // The amount discounted by the advertiser for an order.
            discount_amount_order: Number(discountQuantity?.toFixed(2)) * 100 || undefined,

            // The classification of discount given to an item.
            discount_type: discountType || undefined,

            // A flag to identify marketplace items. A marketplace is where 3rd parties sell their products through another retailer's website.
            marketplace_flag: '',

            // The Merchant Category of the company who offers the product.
            merchant_store_category: 'travel',

            // The Merchant ID of the company who offers the product.
            merchant_store_id: RAKUTEN_MERCHANT_ID,

            // The date a travel reservation begins.
            reservation_start_date: formattedDepartureDate || undefined,

            // The date a travel reservation ends.
            reservation_end_date: formattedReturnDate || undefined,

            // The location where a traveler begins their trip. This can be a city, airport, cruise port, or other specific location.
            departure_location: isFromBooking && (origin?.name || undefined),

            // The country where a traveler begins their trip.
            departure_country: isFromBooking && (origin?.countryName || undefined),
        });
    };

    const checkConsentAcceptance = () => {
        const cookieYesCookie = document?.cookie?.split('; ').find((row) => row?.startsWith('cookieyes-consent='));
        if (cookieYesCookie && cookieYesCookie?.includes('analytics:yes')) {
            setRakutenCookie();
        }
    };

    useEffect(() => {
        if (isLocalEnv) return undefined;

        // Function to start observing the element
        const observeElement = (element: Element) => {
            // Create a MutationObserver instance
            const observer = new MutationObserver((mutationsList) => {
                // Look through all mutations that just occured
                mutationsList.forEach((mutation) => {
                    // If the className attribute was modified
                    if (mutation.attributeName === 'class') {
                        // If the new className contains 'cky-hide'
                        if (element?.classList?.contains('cky-hide')) {
                            // Perform your action here
                            checkConsentAcceptance();
                        }
                    }
                });
            });

            // Start observing the element with the configured parameters
            observer.observe(element, { attributes: true });

            // Clean up the observer when the component unmounts
            return () => {
                observer?.disconnect();
            };
        };

        // Function to check if the element exists and start observing it
        const checkElement = () => {
            const element = document.querySelector('div.cky-consent-container');
            if (element) {
                clearInterval(intervalId);
                if (element.classList.contains('cky-hide')) {
                    checkConsentAcceptance();
                } else {
                    observeElement(element);
                }
            }
        };

        // Check for the element every 500 milliseconds
        const intervalId = setInterval(checkElement, 1000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return { rakutenRanSiteID, notifyRakutenPurchase };
};

export default useRakuten;
