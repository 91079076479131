import { FunctionComponent, SVGProps } from 'react';
import { FlightOfferFromAPI } from '@/utils/adapters/flightsAdapter';
import { RateOfferFromAPI } from '@/utils/adapters/hotelsAdapter';

export interface ICitiesResponse {
    country_code: string;
    country_name: string;
    id: number;
    name: string;
    iata_code?: string;
}

export interface ICity {
    countryCode: string;
    countryName: string;
    id: number;
    name: string;
    iataCode: string;
}

export enum DiscountType {
    Percentage = 'percentage',
    Amount = 'amount',
}

export interface Nationality {
    code: string;
    name: string;
}

export interface PhoneCountryCode {
    number: number;
    name: string;
}

export interface PlanInfoResponse {
    id: number;
    slug: string;
    name: string;
    extras: AvailableExtra[];
    discard_price: string;
    increment_per_day: string;
    segment_range: string;
    free_discards: number;
    max_discards: number;
    available_destinations: AvailableDestination[];
}

export interface AvailableDestination {
    id: number;
    iata_code: string;
    image: null;
    name: string;
}

export type DateString = `20${number}${number}-${number}-${number}`;

export interface AbandonedCartResponse {
    id: number;
    reference: string;
    adult_count: number;
    child_count: number;
    baby_count: number;
    duration: number;
    origin: number;
    origin_name: string;
    start_date: DateString;
    end_date: DateString;
    package: number;
    discarded_destinations: number[];
    applied_discount: string | null;
    applied_discount_type: `${DiscountType}` | null;
    applied_travel_present: string | null;
    applied_discount_amount: string | number | null;
    price: string;
    final_price: string;
    day_price: number;
    extras: AbandonedCartExtra[];
    month: string | null;
    discarded_weekend: string | null;
    observations: string;
    language: string;
}

export interface AbandonedCartExtra {
    extra: number;
    from_value: number | null;
    to_value: number | null;
}

export interface AvailableExtra {
    id: number;
    type: string;
    slug: string;
    name: string;
    price_type: string;
    price: string;
    extra_day_price: string;
    percentage: string;
    required: boolean;
    min_value: number | null;
    max_value: number | null;
    steps: number | null;
    file: string | null;
    options: AvailableExtraOption[] | null;
}

export interface AvailableExtraOption {
    id: number;
    slug: string;
    price: string;
    extra_day_price: string;
    name: string;
}

export const enum ExtraSlug {
    accommodation = 'accommodation',
    accommodationGetaway = 'accommodation-getaway',
    typeOfDestination = 'type-of-destination',
    typeOfActivity = 'type-of-activity',
    cancellationInsurance = 'cancellation-insurance',
    travelInsurance = 'travel-insurance',
    outBoundFlight = 'outbound-flight',
    inBoundFlight = 'inbound-flight',
    seatsTogether = 'seats-together',
    breakfast = 'breakfast',
    baggage10kg = 'baggage-10',
    baggage20kg = 'baggage-20',
    travelCancelInsurance = 'travel-cancel-insurance',
    travelCancelInsurancePlus = 'travel-cancel-insurance-plus',
    /* cancellationAndTravelInsurancePlus = 'cancellation-travel-insurance-plus', */
}

export const enum AccommodationSlug {
    hotel = 'hotel',
    hotelGetaway = 'hotel-getaway',
    hotelBreakfast = 'hotel-breakfast',
    ruralHouse = 'rural-house',
    apartment = 'apartment',
    apartmentGetaway = 'apartment-getaway',
    exclusiveRooms = 'exclusive-rooms',
    bnb = 'bnb',
    bnbGetaway = 'bnb-getaway',
}

export const enum CalendarPriceColor {
    standard = '#0eca69',
    average = '#0ec265',
    high = '#2a915c',
}

export type i18nKey = string;
export type LinearGradient = `linear-gradient(${number}deg, #${string}, #${string})`;

export interface Plan {
    id: number;
    slug: string;
    title: i18nKey;
    subtitle: i18nKey;
    summary: i18nKey;
    colors: {
        default: `#${string}`;
        light: `#${string}`;
        dark: `#${string}`;
    };
    planIcon: string;
    cover: { large: string; largeWebp: string; small: string; smallWebp: string };
    card: { jpg: string; webp: string; pack: string };
    planFeatures: i18nKey[];
    active: boolean;
    order: number;
}

export interface PlanInfoSections {
    id: number;
    sections: PlanInfoSection[];
}

export interface PlanInfoSection {
    icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: i18nKey;
    description: i18nKey[];
}

export const enum TravellerGroup {
    adults = 'adults',
    children = 'children',
    babies = 'babies',
}

export interface CalendarDayPrice {
    date: DateString;
    price: string;
}

export interface PaymentInitResponse {
    token: string;
    amount: string;
    processor: string;
    intent_id: string;
    client_secret: string;
    expires_at: string;
    checkout_url: string;
}

export const enum PaymentMethod {
    none = '',
    card = 'card',
    kubepay = 'kubepay',
    paypal = 'paypal',
    scalapay = 'scalapay',
    stripe = 'stripe',
    bankTransfer = 'bankTransfer',
}

export interface ICurrency {
    name: string;
    symbol: string;
    flag?: string;
}

export const enum FunnelType {
    explore = 'explore',
    surprise = 'surprise',
}

export interface CreateAloyTravelResponse {
    id: number;
    reference: string;
    adult_count: number;
    child_count: number;
    baby_count: number;
    origin: number;
    package: number;
    start_date: string;
    end_date: string;
    applied_discount: string | null;
    applied_travel_present: string | null;
    price: string;
    final_price: string;
    observations: string;
    language: string;
    selected_flight_offer: FlightOfferFromAPI;
    flight_offers_count: number;
    selected_hotel_rate: RateOfferFromAPI;
    hotels_count: number;
    target_currency: string;
    exchange_rate: number;
}

export interface HotelOffer {
    offerId: number | null;
    hotelId: number;
    hotelCode: string;
    name: string;
    description: string;
    stars: number;
    rate: number;
    images: HotelImage[];
    address: string;
    services: HotelService[];
    coordinates: [number, number];
    room: HotelRoomOffer;
    bedGroups: BedGroup[];
    distanceFromCenter: number | string;
    relativePrice: number;
    currency: string;
    isRefundable: boolean | null;
    occupancies: string[];
}

export interface HotelRoomOffer {
    name: string;
    images: HotelImage[];
    extras: HotelService[];
}

export interface BedGroup {
    id: number;
    description: string;
}

export interface HotelImage {
    medium: string;
    large: string;
    description: string;
    isHeroImage: boolean;
}

export interface HotelService {
    id: number;
    slug: string;
    description: string;
    hasIcon: boolean;
}

export interface FlightOffer {
    offerId: number;
    departure: FlightDate;
    arrival: FlightDate;
    duration: FlightDuration;
    carrier: FlightCarrier;
    returnDeparture: FlightDate;
    returnArrival: FlightDate;
    returnDuration: FlightDuration;
    returnCarrier: FlightCarrier;
    relativePrice: number | null;
    includedExtraLuggage: number;
    availableExtraLuggage: null;
    targetCurrency: string;
    exchangeRate: number;
    itineraryIds: [string, string];
}

export interface FlightDuration {
    minutes: string;
    hours: string;
}

export interface FlightDate {
    city: string;
    atLocal: string;
    iataCode: string;
    airport: string;
}

export interface FlightCarrier {
    flightClass: string;
    flightNumber: string;
    includedCheckedBags: CheckedBags;
    name: string;
    code: string;
    logo: string;
}

export interface CheckedBags {
    quantity: number;
    weight: string | null;
    weightUnit: string | null;
}

export interface SelectedCombinationResponse {
    selected_flight_offer_id: number | null;
    selected_hotel_rate_id: number | null;
    total_price: number | null;
    target_currency: string;
    exchange_rate: number;
}

export enum FlightType {
    outbound = 'outbound',
    return = 'return',
}

export interface Filters {
    location: string[];
    rating: number;
    stars: number[];
    hotelsSortBy: HotelsSortBy;
    flightsSortBy: FlightsSortBy;
}

export enum HotelsSortBy {
    cheapest = 'cheapest',
    expensive = 'expensive',
    bestOption = 'bestOption',
    central = 'central',
}

export enum FlightsSortBy {
    cheapest = 'cheapest',
    expensive = 'expensive',
    shortest = 'shortest',
    longest = 'longest',
}
