import { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '@/assets/icons/logoFlyKube.png';
import whatsappIcon from '@/assets/icons/whatsapp.svg';
import useResetStates from '@/hooks/useResetStates';
import useRoutes from '@/hooks/useRoutes';
import { useAppSelector } from '@/hooks/useRedux';
import checkIfDateIsInFuture from '@/utils/checkTime';
import styles from './Header.module.scss';
import getSelectedPlan from '@/utils/getSelectedPlan';

const HeaderNav = lazy(() => import('./headerNav/HeaderNav'));
const FunnelSummary = lazy(() => import('./funnelSummary/FunnelSummary'));
const HeaderPayment = lazy(() => import('./paymentSummary/PaymentSummary'));

const HeaderRight = () => {
    const { calendarBasePrice, totalPrice } = useAppSelector((state) => state.booking);
    const { isFunnelRoute, isPaymentRoute, isRouteWithNav } = useRoutes();
    const hasPrice = calendarBasePrice || totalPrice;

    if (isFunnelRoute && hasPrice) return <FunnelSummary />;
    if (isPaymentRoute) return <HeaderPayment />;
    if (isRouteWithNav) return <HeaderNav />;

    return null;
};

const Header = () => {
    const currentPlanId = useAppSelector((state) => state.plan.currentPlan);
    const hasDiscount = useAppSelector((state) => state.discount.existingDiscount);
    const promoData = useAppSelector((state) => state.landing.homeCoverPromoData);
    const promoIsActive = promoData?.slug === '';
    const counterIsActive = checkIfDateIsInFuture(promoData?.countdown);
    const { isAloyPlan } = getSelectedPlan(currentPlanId);
    const { resetGlobalStates } = useResetStates();
    const { t } = useTranslation();
    const {
        routes,
        isHomePage,
        isFunnelRoute,
        isPaymentRoute,
        isLandingGeneric,
        isLandingMarketing,
        isLandingB2B,
        isLandingGifts,
        isLandingHalloween,
    } = useRoutes();

    const goHome = () => {
        if (isHomePage) return;
        resetGlobalStates();
    };

    const showPromoHeader = promoIsActive && !counterIsActive && !isPaymentRoute && !hasDiscount && !isLandingGeneric;

    // These landings have their own header
    if (isLandingMarketing || isLandingB2B || isLandingGifts || isLandingHalloween) return null;

    const onWhatsappButtonClick = () => {
        window.open('https://wa.me/+393314047147', '_blank', 'noopener,noreferrer');
    };

    const homePageRoute = isAloyPlan ? routes.aloyHome : routes.home;

    return (
        <>
            {/* {showPromoHeader && (
                <div className={styles.promoMessageContainer}>
                    <p className={styles.promoMessage}>
                        <Trans i18nKey="headerPromoMessage">
                            EASTER WEEK : 10% OFF on the entire website with the code <span>EASTER10</span>. Last 48h!
                        </Trans>
                    </p>
                </div>
            )} */}
            <header>
                <div className={styles.headerContainer}>
                    <div className={styles.headerLeft}>
                        <Link
                            className={styles.logoContainer}
                            to={homePageRoute}
                            onClick={goHome}
                            aria-label={t('home')}
                        >
                            <img src={logo} className={styles.logo} alt="" />
                        </Link>
                        {isFunnelRoute && (
                            <div className={styles.whatsappButtonContainer}>
                                <button onClick={onWhatsappButtonClick} className={styles.whatsappButton}>
                                    <img src={whatsappIcon} className={styles.whatsappIcon} alt="Whatsapp" />
                                </button>
                            </div>
                        )}
                    </div>
                    <Suspense fallback={null}>
                        <HeaderRight />
                    </Suspense>
                </div>
            </header>
        </>
    );
};

export default Header;
