/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export interface Insurance {
    id: number;
    slug: string;
}

export type ExtrasSliceState = {
    accommodation: { id: number; option: number; slug: string; name: string };
    insurances: Insurance[];
    departureStartHour: number;
    departureEndHour: number;
    arrivalStartHour: number;
    arrivalEndHour: number;
    breakfast: boolean;
    revealDestination: number;
    seatsTogether: boolean;
    baggage: { [key: string]: any; small: number; big: number };
};

export const extrasInitialState: ExtrasSliceState = {
    accommodation: { id: 0, option: 0, slug: '', name: '' },
    insurances: [],
    departureStartHour: 6,
    departureEndHour: 24,
    arrivalStartHour: 6,
    arrivalEndHour: 24,
    breakfast: false,
    revealDestination: 0,
    seatsTogether: false,
    baggage: { small: 0, big: 0 },
};

export const extrasSlice = createSlice({
    name: 'extras',
    initialState: extrasInitialState,
    reducers: {
        setAccommodation: (state, action) => {
            state.accommodation = action.payload;
        },
        toggleInsurance: (state, action) => {
            const insurance = state.insurances.find((i) => i.slug === action.payload.slug);
            if (insurance) {
                const index = state.insurances.indexOf(insurance);
                state.insurances.splice(index, 1);
            } else {
                state.insurances.push({ id: action.payload.id, slug: action.payload.slug });
            }
        },
        /*         toggleCancellationInsurance: (state) => {
            state.cancellationInsurance = !state.cancellationInsurance;
        },
        toggleTravelInsurance: (state) => {
            state.travelInsurance = !state.travelInsurance;
        }, */
        setInsurance: (state, action) => {
            state.insurances = [...state.insurances, action.payload];
        },
        /*        setCancellationInsurance: (state, action) => {
            state.cancellationInsurance = action.payload;
        },
        setTravelInsurance: (state, action) => {
            state.travelInsurance = action.payload;
        }, */
        setDepartureStartHour: (state, action) => {
            state.departureStartHour = action.payload;
        },
        setDepartureEndHour: (state, action) => {
            state.departureEndHour = action.payload;
        },
        setArrivalStartHour: (state, action) => {
            state.arrivalStartHour = action.payload;
        },
        setArrivalEndHour: (state, action) => {
            state.arrivalEndHour = action.payload;
        },
        setBaggages: (state, action) => {
            state.baggage.small = action.payload.small ?? state.baggage.small;
            state.baggage.big = action.payload.big ?? state.baggage.big;
        },
        addBaggage: (state, action) => {
            const { type } = action.payload;
            state.baggage[type] += 1;
        },
        removeBaggage: (state, action) => {
            const { type } = action.payload;
            state.baggage[type] -= 1;
        },
        toggleBreakfast: (state) => {
            state.breakfast = !state.breakfast;
        },
        setBreakfast: (state, action) => {
            state.breakfast = action.payload;
        },
        toggleSeatsTogether: (state) => {
            state.seatsTogether = !state.seatsTogether;
        },
        setSeatsTogether: (state, action) => {
            state.seatsTogether = action.payload;
        },
        setRevealDestination: (state, action) => {
            state.revealDestination = action.payload;
        },
        resetBaggage: (state) => {
            state.baggage = extrasInitialState.baggage;
        },
        resetExtrasPage: (state) => {
            state.baggage = extrasInitialState.baggage;
            state.breakfast = extrasInitialState.breakfast;
            state.seatsTogether = extrasInitialState.seatsTogether;
            state.insurances = extrasInitialState.insurances;
        },
        resetExtrasStates: () => {
            return { ...extrasInitialState };
        },
    },
});

export const {
    setAccommodation,
    /*     toggleCancellationInsurance,
    toggleTravelInsurance,
    setCancellationInsurance,
    setTravelInsurance, */
    toggleInsurance,
    setInsurance,
    setDepartureStartHour,
    setDepartureEndHour,
    setArrivalStartHour,
    setArrivalEndHour,
    setBaggages,
    addBaggage,
    removeBaggage,
    toggleBreakfast,
    setBreakfast,
    toggleSeatsTogether,
    setSeatsTogether,
    setRevealDestination,
    resetBaggage,
    resetExtrasPage,
    resetExtrasStates,
} = extrasSlice.actions;

export default extrasSlice.reducer;
