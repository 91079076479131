/* PLANS IMAGES */
import summer from '@/assets/images/cover/summer.jpg';
import summerWebp from '@/assets/images/cover/summer.webp';
import summerSmall from '@/assets/images/cover/summer_small.jpg';
import summerSmallWebp from '@/assets/images/cover/summer_small_webp.webp';
import summerCard from '@/assets/images/cover/summer_card.jpg';
import summerCardWebp from '@/assets/images/cover/summer_card_webp.webp';
import summerCardPack from '@/assets/images/cover/summer_box.png';
import destination from '@/assets/images/cover/destination.jpg';
import destinationWebp from '@/assets/images/cover/destination.webp';
import destinationSmall from '@/assets/images/cover/destination_small.jpg';
import destinationSmallWebp from '@/assets/images/cover/destination_small_webp.webp';
import destinationCard from '@/assets/images/cover/destination_card.jpg';
import destinationCardWebp from '@/assets/images/cover/destination_card_webp.webp';
import destinationCardPack from '@/assets/images/cover/destination_box.png';
import all from '@/assets/images/cover/all.jpg';
import allWebp from '@/assets/images/cover/all.webp';
import allSmall from '@/assets/images/cover/all_small.jpg';
import allSmallWebp from '@/assets/images/cover/all_small_webp.webp';
import allCard from '@/assets/images/cover/all_card.jpg';
import allCardWebp from '@/assets/images/cover/all_card_webp.webp';
import multi from '@/assets/images/cover/multi.jpg';
import multiWebp from '@/assets/images/cover/multi.webp';
import multiSmall from '@/assets/images/cover/multi_small.jpg';
import multiSmallWebp from '@/assets/images/cover/multi_small_webp.webp';
import multiCard from '@/assets/images/cover/multi_card.jpg';
import multiCardWebp from '@/assets/images/cover/multi_card_webp.webp';
import multiCardPack from '@/assets/images/cover/multi_box.png';
import vip from '@/assets/images/cover/vip.jpg';
import vipWebp from '@/assets/images/cover/vip.webp';
import vipSmall from '@/assets/images/cover/vip_small.jpg';
import vipSmallWebp from '@/assets/images/cover/vip_small_webp.webp';
import vipCard from '@/assets/images/cover/vip_card.jpg';
import vipCardWebp from '@/assets/images/cover/vip_card_webp.webp';
import vipCardPack from '@/assets/images/cover/vip_box.png';
import getaway from '@/assets/images/cover/escapade.jpg';
import getawayWebp from '@/assets/images/cover/escapade.webp';
import getawaySmall from '@/assets/images/cover/escapade_small.jpg';
import getawaySmallWebp from '@/assets/images/cover/escapade_small_webp.webp';
import getawayCard from '@/assets/images/cover/escapade_card.jpg';
import getawayCardWebp from '@/assets/images/cover/escapade_card_webp.webp';
import getawayCardPack from '@/assets/images/cover/escapade_box.png';
import national from '@/assets/images/cover/national.jpg';
import nationalWebp from '@/assets/images/cover/national.webp';
import nationalSmall from '@/assets/images/cover/national_small.jpg';
import nationalSmallWebp from '@/assets/images/cover/national_small_webp.webp';
import nationalCard from '@/assets/images/cover/national_card.jpg';
import nationalCardWebp from '@/assets/images/cover/national_card_webp.webp';
import nationalCardPack from '@/assets/images/cover/national_box.png';
import explore from '@/assets/images/cover/aloy.jpg';
import exploreWebp from '@/assets/images/cover/aloy.webp';
import exploreSmall from '@/assets/images/cover/aloy_small.jpg';
import exploreSmallWebp from '@/assets/images/cover/aloy_small_webp.webp';
import christmas from '@/assets/images/cover/christmas.jpg';
import christmasWebp from '@/assets/images/cover/christmas.webp';
import christmasSmall from '@/assets/images/cover/christmas_small.jpg';
import christmasSmallWebp from '@/assets/images/cover/christmas_small.webp';

/* PLANS ICONS */
import surprisePlanIcon from '@/assets/icons/plan-surprise.svg';
import multiPlanIcon from '@/assets/icons/plan-multi.svg';
import getAwayPlanIcon from '@/assets/icons/plan-getaway.svg';
import summerPlanIcon from '@/assets/icons/plan-summer.svg';
import allPlanIcon from '@/assets/icons/plan-all.svg';
import vipPlanIcon from '@/assets/icons/plan-vip.svg';
import nationalPlanIcon from '@/assets/icons/plan-national.svg';
import aloyPlanIcon from '@/assets/icons/world.svg';
import christmasPlanIcon from '@/assets/icons/plan-christmas.png';

export const IMAGES = {
    surprisePlanIcon,
    multiPlanIcon,
    getAwayPlanIcon,
    summerPlanIcon,
    allPlanIcon,
    vipPlanIcon,
    nationalPlanIcon,
    aloyPlanIcon,
    christmasPlanIcon,
    summer,
    summerWebp,
    summerSmall,
    summerSmallWebp,
    summerCard,
    summerCardWebp,
    summerCardPack,
    destination,
    destinationWebp,
    destinationSmall,
    destinationSmallWebp,
    destinationCard,
    destinationCardWebp,
    destinationCardPack,
    all,
    allWebp,
    allSmall,
    allSmallWebp,
    allCard,
    allCardWebp,
    multi,
    multiWebp,
    multiSmall,
    multiSmallWebp,
    multiCard,
    multiCardWebp,
    multiCardPack,
    vip,
    vipWebp,
    vipSmall,
    vipSmallWebp,
    vipCard,
    vipCardWebp,
    vipCardPack,
    getaway,
    getawayWebp,
    getawaySmall,
    getawaySmallWebp,
    getawayCard,
    getawayCardWebp,
    getawayCardPack,
    national,
    nationalWebp,
    nationalSmall,
    nationalSmallWebp,
    nationalCard,
    nationalCardWebp,
    nationalCardPack,
    explore,
    exploreWebp,
    exploreSmall,
    exploreSmallWebp,
    christmas,
    christmasWebp,
    christmasSmall,
    christmasSmallWebp,
};

export default IMAGES;
