export const checkIfDateIsInFuture = (date: string | null | undefined) => {
    if (date === '' || !date) return false;

    const dateToCheck = new Date(date).getTime();
    const currentDate = new Date().getTime();
    const isInFuture = dateToCheck > currentDate;

    return isInFuture;
};

export default checkIfDateIsInFuture;
