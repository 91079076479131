import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';

const useRoutes = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation(['routes']);

    // IMPORTANTE: Si se cambia alguna ruta hay que cambiarla también en los archivos routes de i18n
    const routes = {
        home: t('routes:home'),
        homeLocale: t('routes:homeLocale'),
        flykubeHome: t('routes:surprise'),
        aloyHome: t('routes:aloyHome'),
        christmasHome: t('routes:christmasHome'),
        help: t('routes:help'),
        hotels: t('routes:hotels'),
        reviews: t('routes:reviews'),
        contact: t('routes:contact'),
        airlines: t('routes:airlines'),
        howItWorks: t('routes:howItWorks'),
        ourExperiences: t('routes:ourExperiences'),
        privacy: t('routes:privacy'),
        terms: t('routes:terms'),
        notfound: t('routes:notfound'),

        experienceInfo: t('routes:experienceInfo'),
        experienceInfoDestination: t('routes:experienceInfoDestination'),
        experienceInfoMulti: t('routes:experienceInfoMulti'),
        experienceInfoGetaway: t('routes:experienceInfoGetaway'),
        experienceInfoSummer: t('routes:experienceInfoSummer'),
        experienceInfoAllSuprise: t('routes:experienceInfoAllSuprise'),
        experienceInfoVip: t('routes:experienceInfoVip'),
        experienceInfoNational: t('routes:experienceInfoNational'),

        gift: t('routes:gift'),
        giftCard: t('routes:giftCard'),
        giftCardPayment: t('routes:giftCardPayment'),
        giftExperienceType: t('routes:giftExperienceType'),
        giftExperienceOptions: t('routes:giftExperienceOptions'),
        giftExperiencePayment: t('routes:giftExperiencePayment'),

        calendar: t('routes:calendar'),
        discardDestinations: t('routes:discardDestinations'),
        destinationType: t('routes:destinationType'),
        activityType: t('routes:activityType'),
        extras: t('routes:extras'),
        passengers: t('routes:passengers'),
        payment: t('routes:payment'),
        almostThere: t('routes:almostThere'),
        almostThereRef: `${t('routes:almostThere')}/:bookingReference?`,
        congratsBooking: t('routes:congratsBooking'),
        congratsGiftCard: t('routes:congratsGiftCard'),
        congratsGiftTravel: t('routes:congratsGiftTravel'),

        bestResult: t('bestResult'),
        chooseFlight: t('chooseFlight'),
        chooseHotel: t('chooseHotel'),
        hotelDetails: t('hotelDetails'),
        hotelPreview: `/${i18n.language}/booking/hotel-preview/:travelId/:hotelOfferId`,

        landingGeneric: t('routes:landingGeneric'),
        landingMarketing: t('routes:landingMarketing'),
        landingB2B: t('routes:landingB2B'),
        landingGifts: t('routes:landingGifts'),
        landingHalloween: t('routes:landingHalloween'),
        feedback: `/${i18n.language}/feedback/:token`,
        recoverBooking: `/${i18n.language}/booking/recover/:bookingReference/:status?`,
        paymentConfirm: `/${i18n.language}/confirm/:bookingReference/`,
        passengersFillRemaining: `/${i18n.language}/passengers/:bookingReference`,
    };

    const experiencesInfoRoutes = [
        routes.experienceInfo,
        routes.experienceInfoDestination,
        routes.experienceInfoMulti,
        routes.experienceInfoGetaway,
        routes.experienceInfoSummer,
        routes.experienceInfoAllSuprise,
        routes.experienceInfoVip,
        routes.experienceInfoNational,
    ];

    const routesWithFooter = [
        routes.home,
        routes.homeLocale,
        routes.flykubeHome,
        routes.aloyHome,
        routes.help,
        routes.hotels,
        routes.reviews,
        routes.contact,
        routes.airlines,
        routes.howItWorks,
        routes.privacy,
        routes.terms,
        routes.notfound,
        routes.landingGeneric,
        routes.landingMarketing,
        routes.landingB2B,
        routes.landingGifts,
        routes.landingHalloween,
        ...experiencesInfoRoutes,
    ];

    const routesWithChat = [
        routes.home,
        routes.homeLocale,
        routes.flykubeHome,
        routes.help,
        routes.reviews,
        routes.contact,
        routes.airlines,
        routes.howItWorks,
        routes.privacy,
        routes.terms,
        routes.notfound,
        routes.gift,
        routes.giftCard,
        routes.giftExperienceType,
        routes.giftExperienceOptions,
        routes.landingGeneric,
        routes.landingB2B,
        routes.landingGifts,
        routes.landingHalloween,
        ...experiencesInfoRoutes,
    ];

    const paymentRoutes = [routes.payment, routes.giftCardPayment, routes.giftExperiencePayment, routes.recoverBooking];

    const reservationFunnelRoutes = [
        routes.calendar,
        routes.discardDestinations,
        routes.activityType,
        routes.destinationType,
        routes.extras,
        routes.passengers,
        routes.bestResult,
        routes.hotelDetails,
    ];

    const routesWithoutNav = [
        routes.giftCard,
        routes.giftExperienceType,
        routes.giftExperienceOptions,
        routes.almostThere,
        routes.paymentConfirm,
        routes.chooseHotel,
        routes.chooseFlight,
        ...reservationFunnelRoutes,
        ...paymentRoutes,
    ];

    const getCurrentPathname = () => {
        const pathnameHasFinalSlash = location.pathname.match('/.*/$');
        if (pathnameHasFinalSlash) {
            const pathnameWithoutFinalSlash = location.pathname.replace(/\/+$/, '');
            return pathnameWithoutFinalSlash;
        }
        return location.pathname;
    };

    const currentPathname = getCurrentPathname();

    const isFeedbackPage = matchPath({ path: routes.feedback }, currentPathname);
    const isLandingGeneric = matchPath({ path: routes.landingGeneric }, currentPathname);
    const isLandingMarketing = matchPath({ path: routes.landingMarketing }, currentPathname);
    const isLandingB2B = matchPath({ path: routes.landingB2B }, currentPathname);
    const isLandingGifts = matchPath({ path: routes.landingGifts }, currentPathname);
    const isLandingHalloween = matchPath({ path: routes.landingHalloween }, currentPathname);
    const isRemainingPassengersPage = matchPath({ path: routes.passengersFillRemaining }, currentPathname);
    const isRecoverBookingRoute = matchPath({ path: routes.recoverBooking }, currentPathname);
    const isHotelPreview = matchPath({ path: routes.hotelPreview }, currentPathname);
    const isHomePage =
        currentPathname === routes.home ||
        currentPathname === routes.homeLocale ||
        currentPathname === routes.aloyHome ||
        currentPathname === routes.flykubeHome;

    const isFunnelRoute = reservationFunnelRoutes.includes(currentPathname);
    const isPaymentRoute = paymentRoutes.includes(currentPathname);
    const isRouteWithNav =
        !routesWithoutNav.includes(location.pathname) &&
        !isFeedbackPage &&
        !isRemainingPassengersPage &&
        !isRecoverBookingRoute &&
        !isLandingMarketing &&
        !isHotelPreview;
    const isRouteWithFooter = routesWithFooter.includes(currentPathname) || isLandingGeneric || isLandingMarketing;
    const isRouteWithChat = routesWithChat.includes(currentPathname) && !isRemainingPassengersPage;

    return {
        routes,
        experiencesInfoRoutes,
        currentPathname,
        isHomePage,
        isFunnelRoute,
        isPaymentRoute,
        isRouteWithNav,
        isRouteWithFooter,
        isRouteWithChat,
        isLandingGeneric,
        isLandingMarketing,
        isLandingB2B,
        isLandingGifts,
        isLandingHalloween,
        isRemainingPassengersPage,
    };
};

export default useRoutes;
