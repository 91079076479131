const getCurrencySymbol = (currencyName: string) => {
    if (currencyName === 'EUR') return '€';
    if (currencyName === 'AUD') return '$';
    if (currencyName === 'CAD') return '$';
    if (currencyName === 'CHF') return 'CHF';
    if (currencyName === 'DKK') return 'kr';
    if (currencyName === 'GBP') return '£';
    if (currencyName === 'HKD') return '$';
    if (currencyName === 'JPY') return '¥';
    if (currencyName === 'NOK') return 'kr';
    if (currencyName === 'NZD') return '$';
    if (currencyName === 'SGD') return '$';
    if (currencyName === 'USD') return '$';
    if (currencyName === 'ZAR') return 'R';
    if (currencyName === 'KUBE') return 'KUBE';
    return '€';
};

export default getCurrencySymbol;
