import { useAppDispatch } from '../hooks/useRedux';
import { resetBookingDates, resetBookingStates } from '../redux/bookingSlice';
import { resetExtrasStates } from '../redux/extrasSlice';
import { resetTravellersStates } from '../redux/travellersSlice';
import { resetPlanStates } from '../redux/planSlice';
import { resetGiftStates } from '../redux/giftSlice';
import { resetDiscountStates } from '../redux/discountSlice';
import { resetLandingDefaultDiscounts } from '../redux/landingSlice';

const useResetStates = () => {
    const dispatch = useAppDispatch();

    const resetGlobalStates = () => {
        dispatch(resetBookingStates());
        dispatch(resetExtrasStates());
        dispatch(resetTravellersStates());
        dispatch(resetPlanStates());
        dispatch(resetGiftStates());
        dispatch(resetDiscountStates());
        dispatch(resetLandingDefaultDiscounts());
    };

    const resetExtrasFromExtrasPage = () => {
        dispatch(resetExtrasStates());
    };

    const resetDates = () => {
        dispatch(resetBookingDates());
    };

    return { resetGlobalStates, resetExtrasFromExtrasPage, resetDates };
};

export default useResetStates;
