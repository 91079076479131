import { Navigate, generatePath, useLocation, useParams } from 'react-router-dom';
import useRoutes from '@/hooks/useRoutes';

const PaymentConfirm = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { bookingReference } = useParams<{ bookingReference: string }>();
    const { routes } = useRoutes();

    const orderToken = searchParams.get('orderToken');
    const status = searchParams.get('status');
    const product = searchParams.get('product');

    if (status === 'SUCCESS' && bookingReference) {
        const almostThereUrl = generatePath(routes.almostThereRef, { bookingReference });
        return <Navigate to={almostThereUrl} />;
    }

    const recoverUrl = generatePath(routes.recoverBooking, { bookingReference, status: 'failed' });
    return <Navigate to={recoverUrl} />;
};

export default PaymentConfirm;
