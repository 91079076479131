import { configureStore } from '@reduxjs/toolkit';
import bookingReducer, { BookingSliceState } from './bookingSlice';
import extrasReducer, { ExtrasSliceState } from './extrasSlice';
import travellersReducer, { TravellersSliceState } from './travellersSlice';
import discountReducer, { DiscountSliceState } from './discountSlice';
import giftReducer, { GiftSliceState } from './giftSlice';
import planReducer, { PlanSliceState } from './planSlice';
import landingReducer, { LandingSliceState } from './landingSlice';

export type StorePreloadedState = {
    booking: BookingSliceState;
    extras: ExtrasSliceState;
    travellers: TravellersSliceState;
    discount: DiscountSliceState;
    gift: GiftSliceState;
    plan: PlanSliceState;
    landing: LandingSliceState;
};

function saveToLocalStorage(state: any) {
    try {
        const serialisedState = JSON.stringify(state);
        sessionStorage.setItem('persistantState0', serialisedState);
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage(): StorePreloadedState | undefined {
    // if the store changes, the previous state saved in sessionStorage should be removed
    // and save a new item to avoid state inconsistences when the new page load
    sessionStorage.removeItem('persistantState');

    try {
        const serialisedState = sessionStorage.getItem('persistantState0');
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

const store = configureStore({
    preloadedState: loadFromLocalStorage(),
    reducer: {
        booking: bookingReducer,
        extras: extrasReducer,
        travellers: travellersReducer,
        discount: discountReducer,
        gift: giftReducer,
        plan: planReducer,
        landing: landingReducer,
    },
    devTools: !import.meta.env.PROD,
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
