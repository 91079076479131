/* eslint-disable no-param-reassign */
import axios from 'axios';
import i18next from 'i18next';
import { HotelOffer, ICitiesResponse } from '@/types/types';
import adaptHotelOffers, { RateOfferFromAPI } from './adapters/hotelsAdapter';
import adaptFlights, { FlightOfferFromAPI } from './adapters/flightsAdapter';

const BASE_URL = import.meta.env.VITE_API_BASE_URL || 'http://localhost/';
const KUBEPAY_BASE_URL = import.meta.env.VITE_KUBEPAY_API_BASE_URL || 'https://kubepay.dev.api.kubegroup.co/';

export const APIKit = axios.create({
    baseURL: BASE_URL,
});

export const KUBEAPIKit = axios.create({
    baseURL: KUBEPAY_BASE_URL,
});

// REQUEST INTERCEPTOR
APIKit.interceptors.request.use(
    async (config) => {
        config.headers = config.headers ?? {};
        config.headers['Accept-language'] = i18next.language;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

// REQUEST INTERCEPTOR
KUBEAPIKit.interceptors.request.use(
    async (config) => {
        config.headers = config.headers ?? {};
        config.headers['Accept-language'] = i18next.language;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export const getPlanAvailableOrigins = async (planId: number | string) => {
    return APIKit.get(`packages/${planId}/origins/`)
        .then((res) => {
            const origins = res.data.package_origins as ICitiesResponse[];
            const adaptedOrigins = origins?.map((item) => ({
                id: item.id,
                name: item.name,
                countryCode: item.country_code ?? '',
                countryName: item.country_name ?? '',
                iataCode: item.iata_code ?? '',
            }));
            return adaptedOrigins;
        })
        .catch((err) => {
            console.log(err);
            return [];
        });
};

export const getAvailableCities = async () => {
    return APIKit.get(`travels/cities/`)
        .then((res) => {
            const cities = res.data as ICitiesResponse[];
            const adaptedCities = cities?.map((item) => ({
                id: item.id,
                name: item.name ?? '',
                countryCode: item.country_code ?? '',
                countryName: item.country_name ?? '',
                iataCode: item.iata_code ?? '',
            }));
            return adaptedCities;
        })
        .catch((err) => {
            console.log(err);
            return [];
        });
};

export const getHotelDetailsByOfferId = async (hotelOffer: number | string, locale = 'en') => {
    return APIKit.get(`hotels/rates/${hotelOffer}/`)
        .then((res) => {
            const hotelDetails = res.data as RateOfferFromAPI;
            const adaptedHotelsDetails = adaptHotelOffers([hotelDetails], locale);

            return adaptedHotelsDetails[0];
        })
        .catch((err) => {
            console.log(err);
            return null;
        });
};

export const getAvailableHotelsByTravelId = async (travelId: number | string, page: number = 1, locale = 'en') => {
    /* const apiUrl = `/travels/travels/${travelId}/hotels/?page=${page}`; */
    const apiUrl = `hotels/rates/?travel=${travelId}&is_best_price_for_hotel=True&ordering=inclusive_price_value,-room__hotel__stars,room__hotel__distance&page=${page}`;
    return APIKit.get(apiUrl)
        .then((res) => {
            const totalAvailable = res.data.count as number;
            const availableHotelsOffers = res.data.results as RateOfferFromAPI[];
            const adaptedHotelsOffers = adaptHotelOffers(availableHotelsOffers, locale);

            return { totalAvailable, availableHotels: adaptedHotelsOffers };
        })
        .catch((err) => {
            console.log(err);
            return { totalAvailable: 0, availableHotels: [] };
        });
};

export const getHotelOffersByHotelId = async (
    hotelId: number | string,
    travelId: number | string,
    offersNumber: number = 1,
    locale = 'en',
): Promise<HotelOffer[]> => {
    return APIKit.get(
        `/hotels/rates/?room__hotel=${hotelId}&travel=${travelId}&ordering=inclusive_price_value&page_size=${offersNumber}`,
    )
        .then((res) => {
            const hotelOffers = res.data.results as RateOfferFromAPI[];
            const adaptedHotelOffers = adaptHotelOffers(hotelOffers, locale);
            const hotelOffersToReturn = adaptedHotelOffers.slice(0, offersNumber);

            return hotelOffersToReturn;
        })
        .catch((err) => {
            console.log(err);
            return err;
        });
};

export const getAvailableFlightsOffersByTravelId = async (travelId: number | string, page: number = 1) => {
    return APIKit.get(`/flights/flight_offers/?travel=${travelId}&ordering=price&page=${page}`)
        .then((res) => {
            const totalAvailable = res.data.count as number;
            const availableFlights = res.data.results as FlightOfferFromAPI[];
            const adaptedFlights = adaptFlights(availableFlights);

            return { totalAvailable, availableFlights: adaptedFlights };
        })
        .catch((err) => {
            console.log(err);
            return { totalAvailable: 0, availableFlights: [] };
        });
};

export default APIKit;
