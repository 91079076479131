/* import { DateTime, Duration } from 'luxon'; */
import { FlightOffer } from '@/types/types';

export interface FlightOfferFromAPI {
    id: number;
    itineraries: Itinerary[];
    relative_price: number;
    included_extra_luggage: number;
    available_extra_luggage: null;
    price_updated: boolean;
    seats_sold_out: boolean;
    target_currency: string;
    exchange_rate: number;
}

export interface Itinerary {
    id: number;
    fare_details: FareDetail[];
}

export interface FareDetail {
    id: number;
    includedCheckedBags: IncludedCheckedBags;
    class: null;
    segment: Segment;
    cabin: string;
    fareBasis: string;
    fareId: string;
    brandedFare: null;
}

export interface IncludedCheckedBags {
    quantity: number;
    weight: string | null;
    weightUnit: string | null;
}

export interface Segment {
    id: number;
    departure: FlightDate;
    arrival: FlightDate;
    aircraft: Aircraft;
    operating: Operating;
    co2Emissions: any[];
    carrierCode: string;
    carrierName: string;
    number: string;
    duration: string;
    numberOfStops: number;
    blacklistedInEU: null;
    segmentId: null;
}

export interface Aircraft {
    code: string;
}

export interface Operating {
    id: number;
    sky_id: string;
    carrierCode: string;
    name: string;
}

interface FlightDate {
    id: number;
    at: string;
    at_local: string;
    city_name: string;
    iataCode: string;
    terminal: string | null;
    geoObjectType: string;
    airport_name: string;
}

function minutesToHours(minutes: string) {
    const hours = Math.floor(parseInt(minutes, 10) / 60);
    const remainingMinutes = parseInt(minutes, 10) % 60;
    return `${hours}h ${remainingMinutes}m`;
}

const adaptFlights = (flightOffers: FlightOfferFromAPI[]): FlightOffer[] => {
    const adaptedFlights = flightOffers.map((flight) => {
        const outBoundFare = flight.itineraries[0]?.fare_details[0];
        const returnFare = flight.itineraries[0]?.fare_details?.at(-1);

        const outBoundFlight = outBoundFare?.segment;
        const returnFlight = returnFare?.segment;

        const outBoundFareId = outBoundFare?.fareId;
        const returnFareId = returnFare?.fareId;

        return {
            offerId: flight.id,
            departure: {
                city: outBoundFlight.departure.city_name,
                atLocal: outBoundFlight.departure.at_local,
                iataCode: outBoundFlight.departure.iataCode,
                airport: outBoundFlight.departure.airport_name,
            },
            arrival: {
                city: outBoundFlight.arrival.city_name,
                atLocal: outBoundFlight.arrival.at_local,
                iataCode: outBoundFlight.arrival.iataCode,
                airport: outBoundFlight.arrival.airport_name,
            },
            duration: {
                minutes: outBoundFlight.duration,
                hours: minutesToHours(outBoundFlight.duration),
            },
            carrier: {
                flightClass: outBoundFare.cabin,
                flightNumber: `${outBoundFlight.carrierCode} ${outBoundFlight.number}`,
                includedCheckedBags: outBoundFare.includedCheckedBags,
                name: outBoundFlight.carrierName,
                code: outBoundFlight.carrierCode,
                logo: '',
            },
            returnDeparture: {
                city: returnFlight?.departure.city_name ?? '',
                atLocal: returnFlight?.departure.at_local ?? '',
                iataCode: returnFlight?.departure.iataCode ?? '',
                airport: returnFlight?.departure.airport_name ?? '',
            },
            returnArrival: {
                city: returnFlight?.arrival.city_name ?? '',
                atLocal: returnFlight?.arrival.at_local ?? '',
                iataCode: returnFlight?.arrival.iataCode ?? '',
                airport: returnFlight?.arrival.airport_name ?? '',
            },
            returnDuration: {
                minutes: returnFlight?.duration ?? '0',
                hours: minutesToHours(returnFlight?.duration ?? '0'),
            },
            returnCarrier: {
                flightClass: returnFare?.cabin ?? '',
                flightNumber: `${returnFlight?.carrierCode} ${returnFlight?.number}`,
                includedCheckedBags: returnFare?.includedCheckedBags ?? { quantity: 0, weight: null, weightUnit: null },
                name: returnFlight?.carrierName ?? '',
                code: returnFlight?.carrierCode ?? '',
                logo: '',
            },
            relativePrice: flight.relative_price,
            includedExtraLuggage: flight.included_extra_luggage,
            availableExtraLuggage: flight.available_extra_luggage,
            targetCurrency: flight.target_currency,
            exchangeRate: flight.exchange_rate,
            itineraryIds: [outBoundFareId, returnFareId] as [string, string],
        };
    });

    return adaptedFlights;
};

export default adaptFlights;
