/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export type GiftSliceState = {
    giftCardPrice: number;
    giftCardAmount: number;
    giftTravelPrice: number;
    isRedeemingAGift: boolean;
    transactionId: string;
};

const initialState: GiftSliceState = {
    giftCardPrice: 25,
    giftCardAmount: 1,
    giftTravelPrice: 0,
    isRedeemingAGift: false,
    transactionId: '',
};

export const giftSlice = createSlice({
    name: 'gift',
    initialState,
    reducers: {
        setGiftCardPrice: (state, action) => {
            state.giftCardPrice = Number(action.payload);
        },
        setGiftCardAmount: (state, action) => {
            state.giftCardAmount = Number(action.payload);
        },
        setGiftTravelPrice: (state, action) => {
            state.giftTravelPrice = Number(action.payload);
        },
        setIsRedeemingAGift: (state, action) => {
            state.isRedeemingAGift = action.payload;
        },
        setGiftTransactionId: (state, action) => {
            state.transactionId = action.payload;
        },
        resetGiftStates: () => {
            return { ...initialState };
        },
    },
});

export const {
    setGiftCardPrice,
    setGiftCardAmount,
    setGiftTravelPrice,
    setIsRedeemingAGift,
    setGiftTransactionId,
    resetGiftStates,
} = giftSlice.actions;

export default giftSlice.reducer;
