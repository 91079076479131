import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from './useRedux';
import getSelectedPlan from '../utils/getSelectedPlan';
import useRoutes from './useRoutes';

const useHelmet = () => {
    const { routes, currentPathname, experiencesInfoRoutes } = useRoutes();
    const plan = useAppSelector((state) => state.plan.currentPlan);
    const { t, i18n } = useTranslation(['seo']);
    const {
        isDestinationPlan,
        isMultiDestinationPlan,
        isGetawayPlan,
        isSummerPlan,
        isAllSurprisePlan,
        isVipPlan,
        isNationalPlan,
    } = getSelectedPlan(plan);

    let title = t('defaultTitle');
    let description = t('defaultDescription');

    if (
        experiencesInfoRoutes.includes(currentPathname) ||
        currentPathname === routes.home ||
        currentPathname === routes.homeLocale
    ) {
        if (isDestinationPlan) {
            title = t('defaultTitle');
            description = t('defaultDescription');
        }
        if (isMultiDestinationPlan) {
            title = t('multidestinationTitle');
            description = t('multidestinationDescription');
        }
        if (isGetawayPlan) {
            title = t('getawayTitle');
            description = t('getawayDescription');
        }
        if (isSummerPlan) {
            title = t('beachTitle');
            description = t('beachDescription');
        }
        if (isAllSurprisePlan) {
            title = t('surpriseTitle');
            description = t('surpriseDescription');
        }
        if (isVipPlan) {
            title = t('vipTitle');
            description = t('vipDescription');
        }
        if (isNationalPlan) {
            title = t('nationalTitle');
            description = t('nationalDescription');
        }
    }

    if (currentPathname === routes.help) {
        title = t('helpTitle');
        description = t('helpDescription');
    }

    if (currentPathname === routes.hotels) {
        title = t('hotelTitle');
        description = t('hotelDescription');
    }

    if (currentPathname === routes.reviews) {
        title = t('reviewTitle');
        description = t('reviewDescription');
    }

    if (currentPathname === routes.contact) {
        title = t('contactTitle');
        description = t('contactDescription');
    }

    if (currentPathname === routes.airlines) {
        title = t('airlinesTitle');
        description = t('airlinesDescription');
    }

    if (currentPathname === routes.privacy) {
        title = t('privacyTitle');
        description = t('privacyDescription');
    }

    if (currentPathname === routes.terms) {
        title = t('termsTitle');
        description = t('termsDescription');
    }

    if (currentPathname === routes.gift) {
        title = t('giftMainTitle');
        description = t('giftMainDescription');
    }

    if (currentPathname === routes.giftCard) {
        title = t('giftCardTitle');
        description = t('giftCardDescription');
    }

    if (currentPathname === routes.giftCardPayment) {
        title = t('giftCardTitle');
        description = t('giftCardDescription');
    }

    if (currentPathname === routes.giftExperienceOptions) {
        title = t('giftTravelTitle');
        description = t('giftTravelDescription');
    }

    if (currentPathname === routes.giftExperiencePayment) {
        title = t('giftTravelTitle');
        description = t('giftTravelDescription');
    }

    const isRouteToPreventIndexing = currentPathname === routes.terms || currentPathname === routes.privacy;

    const HelmetContainer = () => (
        <Helmet>
            <html lang={i18n.language} />
            <title>{title} - Flykube.com</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={`${title} - Flykube.com`} />
            <meta property="og:description" content={description} />
            {isRouteToPreventIndexing && <meta name="robots" content="noindex" />}
            <link rel="canonical" href={`https://flykube.com${currentPathname}`} />
        </Helmet>
    );

    return { HelmetContainer };
};

export default useHelmet;
