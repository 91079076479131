/* eslint-disable */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICity, FunnelType, Filters, HotelsSortBy, FlightsSortBy, PaymentMethod } from '@/types/types';
import getCurrencySymbol from '../utils/getCurrencySymbol';

const today = new Date();
today.setDate(today.getDate() + 4);
const currentMonth = today.getMonth() + 1;

export type BookingSliceState = {
    origin: ICity;
    destination: ICity;
    month: { number: number; fullDate: string };
    from: string;
    to: string;
    days: number;
    typeOfActivity: { id: number; option: number; slug: string };
    typeOfDestination: { id: number; option: number; slug: string };
    currency: { name: string; symbol: string; exchangeRate: number };
    calendarPrices: any;
    discardedCities: any;
    calendarBasePrice: number;
    budget: number;
    filters: Filters;
    totalPrice: number;
    totalPriceCrypto: number;
    discardedWeekend: string;
    transactionId: string | number;
    transactionDate: string;
    hotelId: number | null;
    hotelOfferId: number | null;
    hotelBedGroupId: number | null;
    flightId: number | null;
    flightsItineraryIds: [string, string];
    reference: string;
    funnelType: FunnelType;
    isInUS: boolean;
    isFlashOffer: boolean;
    paymentMethod: PaymentMethod;
};

export const bookingInitialState: BookingSliceState = {
    origin: { id: 0, name: '', countryCode: '', countryName: '', iataCode: '' },
    destination: { id: 0, name: '', countryCode: '', countryName: '', iataCode: '' },
    month: { number: currentMonth, fullDate: '' },
    from: '',
    to: '',
    days: 3,
    typeOfActivity: { id: 0, option: 0, slug: '' },
    typeOfDestination: { id: 0, option: 0, slug: '' },
    currency: { name: 'EUR', symbol: '€', exchangeRate: 1 },
    calendarPrices: [],
    discardedCities: [],
    calendarBasePrice: 0,
    budget: 0,
    filters: {
        location: [],
        rating: 0,
        stars: [],
        hotelsSortBy: HotelsSortBy.bestOption,
        flightsSortBy: FlightsSortBy.cheapest,
    },
    totalPrice: 0,
    totalPriceCrypto: 0,
    discardedWeekend: '',
    hotelId: null,
    hotelOfferId: null,
    hotelBedGroupId: null,
    flightId: null,
    flightsItineraryIds: ['', ''],
    transactionId: '',
    transactionDate: '',
    reference: '',
    funnelType: FunnelType.surprise,
    isInUS: false,
    isFlashOffer: false,
    paymentMethod: PaymentMethod.card,
};

export const bookingSlice = createSlice({
    name: 'booking',
    initialState: bookingInitialState,
    reducers: {
        toggleDiscardedCity: (state, action) => {
            if (!state.discardedCities.includes(action.payload)) {
                state.discardedCities.push(action.payload);
            } else {
                state.discardedCities.splice(state.discardedCities.indexOf(action.payload), 1);
            }
        },
        setOrigin: (state, action: PayloadAction<ICity>) => {
            state.origin = action.payload;
        },
        setDestination: (state, action: PayloadAction<ICity>) => {
            state.destination = action.payload;
        },
        setFrom: (state, action) => {
            state.from = action.payload;
        },
        setTo: (state, action) => {
            state.to = action.payload;
        },
        setDays: (state, action) => {
            state.days = action.payload;
        },
        setMonth: (state, action) => {
            state.month = { number: action.payload.number, fullDate: action.payload.fullDate };
        },
        setDiscardedCities: (state, action) => {
            state.discardedCities = action.payload;
        },
        setDiscardedWeekend: (state, action) => {
            state.discardedWeekend = action.payload;
        },
        setCurrency: (state, action) => {
            const currencyName = action.payload ?? 'EUR';
            const symbol = getCurrencySymbol(currencyName);
            state.currency = { ...state.currency, name: currencyName, symbol };
        },
        setExchangeRate: (state, action) => {
            state.currency.exchangeRate = action.payload;
        },
        setCalendarPrices: (state, action) => {
            state.calendarPrices = action.payload;
        },
        setTypeOfActivity: (state, action) => {
            state.typeOfActivity = action.payload;
        },
        setTypeOfDestination: (state, action) => {
            state.typeOfDestination = action.payload;
        },
        setCalendarBasePrice: (state, action) => {
            state.calendarBasePrice = action.payload;
        },
        setBudget: (state, action) => {
            state.budget = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setHotelsSortBy: (state, action) => {
            state.filters.hotelsSortBy = action.payload;
        },
        setFlightsSortBy: (state, action) => {
            state.filters.flightsSortBy = action.payload;
        },
        setTotalPrice: (state, action) => {
            state.totalPrice = Number(action.payload);
        },
        setTotalPriceCrypto: (state, action) => {
            const newPrice = Number(action.payload).toFixed(6);
            state.totalPriceCrypto = Number(newPrice);
        },
        setHotelId: (state, action) => {
            state.hotelId = action.payload;
        },
        setHotelOfferId: (state, action) => {
            state.hotelOfferId = action.payload;
        },
        setHotelBedGroupId: (state, action) => {
            state.hotelBedGroupId = action.payload;
        },
        setFlightId: (state, action) => {
            state.flightId = action.payload;
        },
        setFlightsItineraryIds: (state, action) => {
            state.flightsItineraryIds = action.payload;
        },
        setBookingTransactionId: (state, action) => {
            state.transactionId = action.payload;
        },
        setBookingTransactionDate: (state, action) => {
            state.transactionDate = action.payload;
        },
        setBookingReference: (state, action) => {
            state.reference = action.payload;
        },
        setFunnelType: (state, action) => {
            state.funnelType = action.payload;
        },
        setIsInUS: (state, action) => {
            state.isInUS = action.payload;
        },
        setIsFlashOffer: (state, action) => {
            state.isFlashOffer = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.paymentMethod = action.payload;
        },
        resetPrices: (state) => {
            state.calendarPrices = bookingInitialState.calendarPrices;
            state.calendarBasePrice = bookingInitialState.calendarBasePrice;
        },
        resetBookingDates: (state) => {
            state.from = bookingInitialState.from;
            state.to = bookingInitialState.to;
            state.month = bookingInitialState.month;
            state.days = bookingInitialState.days;
        },
        resetBookingOriginDestination: (state) => {
            state.origin = bookingInitialState.origin;
            state.destination = bookingInitialState.destination;
        },
        resetTransactionId: (state) => {
            state.transactionId = bookingInitialState.transactionId;
        },
        resetBookingStates: (state) => {
            return {
                ...bookingInitialState,
                origin: state.origin,
                destination: state.destination,
                from: state.from,
                to: state.to,
                funnelType: state.funnelType,
                currency: state.currency,
                isInUS: state.isInUS,
            };
        },
    },
});

export const {
    toggleDiscardedCity,
    setOrigin,
    setDestination,
    setFrom,
    setTo,
    setDays,
    setMonth,
    setDiscardedCities,
    setDiscardedWeekend,
    setCurrency,
    setExchangeRate,
    setCalendarPrices,
    setTypeOfActivity,
    setTypeOfDestination,
    setCalendarBasePrice,
    setBudget,
    setFilters,
    setHotelsSortBy,
    setFlightsSortBy,
    setTotalPrice,
    setTotalPriceCrypto,
    setBookingTransactionId,
    setBookingTransactionDate,
    setBookingReference,
    setFunnelType,
    setIsInUS,
    setIsFlashOffer,
    setPaymentMethod,
    resetPrices,
    setHotelId,
    setHotelOfferId,
    setHotelBedGroupId,
    setFlightId,
    setFlightsItineraryIds,
    resetTransactionId,
    resetBookingDates,
    resetBookingOriginDestination,
    resetBookingStates,
} = bookingSlice.actions;

export default bookingSlice.reducer;
