import plans from '@/database/plans';
import { useAppSelector } from './useRedux';
import { Plan } from '@/types/types';
import { IPlanInfo } from '@/redux/planSlice';
import getSelectedPlan from '@/utils/getSelectedPlan';
import useRoutes from './useRoutes';

interface MinDaysProps {
    planConfig: Plan;
    planAdminData: IPlanInfo | undefined;
    setDefaultDays: boolean;
}

interface FromPriceProps {
    showMinPrice?: boolean;
}

function getPlanMinDays({ planConfig, planAdminData, setDefaultDays }: MinDaysProps) {
    const { isMultiDestinationPlan } = getSelectedPlan(planConfig.id);
    const defaultAllPlansMinDays = 3;
    const defaultMultidestinationMinDays = 5;

    const defaultMinDays = isMultiDestinationPlan ? defaultMultidestinationMinDays : defaultAllPlansMinDays;
    const daysArray = planAdminData?.days;

    const planMinDays = daysArray?.[0] ?? defaultMinDays;

    return setDefaultDays ? defaultMinDays : planMinDays;
}

const usePlan = (planId?: number) => {
    const discountIsActive = useAppSelector((state) => state.discount.isOfferDiscount);
    const currentPlanId = useAppSelector((state) => state.plan.currentPlan);
    const seletedDays = useAppSelector((state) => state.booking.days);
    const plansInfo = useAppSelector((state) => state.plan.plansInfo);
    const { routes, currentPathname } = useRoutes();
    const isGiftTravelPage = currentPathname === routes.giftExperienceOptions;

    const planIdToFind = planId ?? currentPlanId;

    const planConfig = plans.find((planItem: Plan) => planItem.id === planIdToFind) ?? plans[0];
    const planAdminData = plansInfo.find((plan: IPlanInfo) => plan.id === planIdToFind);
    const planMinDays = getPlanMinDays({
        planConfig,
        planAdminData,
        setDefaultDays: discountIsActive || isGiftTravelPage,
    });

    function getPlanFromPrice({ showMinPrice }: FromPriceProps) {
        const { isMultiDestinationPlan } = getSelectedPlan(planConfig.id);
        const planAvailableDays = planAdminData?.days ?? [3];
        const daysToShowPrice = showMinPrice ? planAvailableDays[0] : seletedDays;

        let basePrice = Number(planAdminData?.base_price) || 150;
        const incrementalPrice = Number(planAdminData?.increment_per_day) || 0;
        const baseDays = isMultiDestinationPlan ? 5 : 3;
        const extraDays = daysToShowPrice - baseDays;
        const decrementForTwoDays = Number(planAdminData?.specific_decrement_two_days) || 0;

        if (daysToShowPrice === 2) {
            basePrice -= decrementForTwoDays;
        }

        if (extraDays > 0) {
            basePrice += extraDays * incrementalPrice;
        }

        return Number(basePrice);
    }

    return { planConfig, planAdminData, planMinDays, getPlanFromPrice };
};

export default usePlan;
