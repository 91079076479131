import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { registerSW } from 'virtual:pwa-register';
import imageToPreload from './assets/images/cover/destination_small_webp.webp';
import store from './redux/store';
import App from './App';
import './i18n/config';
import './assets/fonts/fonts.scss';
import './index.scss';

const imageElement = new Image();
imageElement.src = imageToPreload;

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>,
);

if ('serviceWorker' in navigator) {
    registerSW();
}
