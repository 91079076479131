/* eslint-disable */
import { AvailableExtra, ExtraSlug, FlightOffer, HotelOffer, ICity } from '@/types/types';
import { createSlice } from '@reduxjs/toolkit';

export interface IPlanInfo {
    name: string;
    id: number;
    slug: string;
    base_price: number;
    days: number[];
    segment_range: string;
    increment_per_day: string;
    specific_decrement_two_days: string;
}

export type PlanSliceState = {
    currentPlan: number;
    name: string;
    slug: string;
    freeDiscards: number;
    maxDiscards: number;
    availableDestinations: any;
    availableExtras: any;
    availableHotels: HotelOffer[];
    availableHotelRooms: HotelOffer[];
    availableFlights: FlightOffer[];
    availableCities: ICity[];
    prices: {
        logPrices: boolean;
        travellingAlonePrice: number;
        babyPrice: number;
        discardDestinationPrice: number;
        discardWeekendPrice: number;
    };
    plansInfo: IPlanInfo[];
};

const defaultPlan = 1; // 'Summer surprise'

export const planInitialState: PlanSliceState = {
    currentPlan: defaultPlan,
    name: '',
    slug: '',
    freeDiscards: 3,
    maxDiscards: 0,
    availableDestinations: [],
    availableExtras: [],
    availableHotels: [],
    availableHotelRooms: [],
    availableFlights: [],
    availableCities: [],
    prices: {
        logPrices: false,
        travellingAlonePrice: 50,
        babyPrice: 50,
        discardDestinationPrice: 5,
        discardWeekendPrice: 25,
    },
    plansInfo: [],
};

export const planSlice = createSlice({
    name: 'plan',
    initialState: planInitialState,
    reducers: {
        setCurrentPlan: (state, action) => {
            state.currentPlan = action.payload || defaultPlan;
        },
        toggleLogPrices: (state) => {
            state.prices.logPrices = !state.prices.logPrices;
        },
        setPlansInfo: (state, action) => {
            state.plansInfo = action.payload;
        },
        setCurrentPlanInfo: (state, action) => {
            const { name, slug, available_destinations, free_discards, max_discards, discard_price, extras } =
                action.payload;

            const newAvailableExtras = extras.filter((extra: any) => {
                const alreadyExist = state.availableExtras.some(
                    (availableExtra: AvailableExtra) => availableExtra.id === extra.id,
                );
                const isAnInsurance =
                    extra.slug === ExtraSlug.travelCancelInsurance ||
                    extra.slug === ExtraSlug.travelCancelInsurancePlus;

                return !alreadyExist && !isAnInsurance;
            });
            state.name = name;
            state.slug = slug;
            state.availableDestinations = available_destinations;
            state.freeDiscards = free_discards;
            state.prices.discardDestinationPrice = Number(discard_price);
            state.maxDiscards = max_discards;
            state.availableExtras = [...state.availableExtras, ...newAvailableExtras];
        },
        setAvailableExtras: (state, action) => {
            state.availableExtras = action.payload;
        },
        addAvailableExtras: (state, action) => {
            const newAvailableExtras = action.payload.filter(
                (extra: any) =>
                    !state.availableExtras.some((availableExtra: AvailableExtra) => availableExtra.id === extra.id),
            );
            state.availableExtras = [...state.availableExtras, ...newAvailableExtras];
        },
        setAvailableDestinations: (state, action) => {
            state.availableDestinations = action.payload;
        },
        setAvailableHotels: (state, action) => {
            state.availableHotels = action.payload;
        },
        updateHotelOffer: (state, action) => {
            const { hotelId, ...propertiesToUpdate } = action.payload;

            state.availableHotels = state.availableHotels.map((hotel) =>
                hotel.hotelId === hotelId ? { ...hotel, ...propertiesToUpdate } : hotel,
            );
        },
        setAvailableHotelRooms: (state, action) => {
            state.availableHotelRooms = action.payload;
        },
        setAvailableFlights: (state, action) => {
            state.availableFlights = action.payload;
        },
        setAvailableCities: (state, action) => {
            state.availableCities = action.payload;
        },
        resetPlanStates: (state) => {
            const prices = { ...planInitialState.prices, logPrices: state.prices.logPrices };
            const plansInfo = [...state.plansInfo];
            return { ...planInitialState, currentPlan: state.currentPlan, prices, plansInfo };
        },
    },
});

export const {
    setCurrentPlan,
    toggleLogPrices,
    setPlansInfo,
    setCurrentPlanInfo,
    setAvailableExtras,
    addAvailableExtras,
    setAvailableDestinations,
    setAvailableHotels,
    updateHotelOffer,
    setAvailableHotelRooms,
    setAvailableFlights,
    setAvailableCities,
    resetPlanStates,
} = planSlice.actions;

export default planSlice.reducer;
