/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export interface IPromoData {
    slug?: string | undefined;
    button_text1?: string | undefined;
    button_text2?: string | undefined;
    button_url?: string | undefined;
    countdown?: string | null | undefined;
    gradient_color1?: string | undefined;
    gradient_color2?: string | undefined;
    image?: string | undefined;
    image_mobile?: string | undefined;
    newsletter_translation?: string | undefined;
    popup_button_text?: string | undefined;
    popup_description?: string | undefined;
    popup_image?: string | undefined;
    popup_subtitle?: string | undefined;
    popup_title?: string | undefined;
    title?: string | undefined;
    subtitle?: string | undefined;
}

export interface IGiftCardsDiscounts {
    originalPrice: string;
    percentageDiscount: string;
    discountCode: string;
}

export type LandingSliceState = {
    isActive: boolean;
    homeCoverPromoData: IPromoData;
    giftCardsDiscounts: IGiftCardsDiscounts[];
};

const initialState: LandingSliceState = {
    isActive: false,
    homeCoverPromoData: {},
    giftCardsDiscounts: [],
};

export const landingSlice = createSlice({
    name: 'landing',
    initialState,
    reducers: {
        setLandingActive: (state, action) => {
            state.isActive = action.payload;
        },
        setHomeData: (state, action) => {
            state.homeCoverPromoData = action.payload;
        },
        setGiftCardsDiscounts: (state, action) => {
            state.giftCardsDiscounts = action.payload;
        },
        resetLandingDefaultDiscounts: (state) => {
            state.giftCardsDiscounts = initialState.giftCardsDiscounts;
        },
        resetLandingStates: () => {
            return { ...initialState };
        },
    },
});

export const {
    setLandingActive,
    setHomeData,
    setGiftCardsDiscounts,
    resetLandingDefaultDiscounts,
    resetLandingStates,
} = landingSlice.actions;

export default landingSlice.reducer;
