import { useAppSelector } from './useRedux';
import getSelectedPlan from '../utils/getSelectedPlan';
import { AccommodationSlug, DiscountType, ExtraSlug, FlightOffer, HotelOffer } from '@/types/types';

const childrenDiscount = 0.05;
const babyPrice = 50;
const defaultExchangeRate = 1;

const usePrices = () => {
    const selectedPlanSlug = useAppSelector((state) => state.plan.slug);
    const selectedPlanId = useAppSelector((state) => state.plan.currentPlan);
    const selectedFlightId = useAppSelector((state) => state.booking.flightId);
    const selectedHotelOfferId = useAppSelector((state) => state.booking.hotelOfferId);
    const { isGetawayPlan, isAloyPlan } = getSelectedPlan(selectedPlanId);
    const basePricePerPerson = useAppSelector((state) => state.booking.calendarBasePrice);
    const aloyTotalPrice = useAppSelector((state) => state.booking.totalPrice);
    const days = useAppSelector((state) => state.booking.days);
    const adultsNumber = useAppSelector((state) => state.travellers.adults.length);
    const childrenNumber = useAppSelector((state) => state.travellers.children.length);
    const babiesNumber = useAppSelector((state) => state.travellers.babies.length);
    const totalTravellers = adultsNumber + childrenNumber + babiesNumber;
    const freeAvailableDiscards = useAppSelector((state) => state.plan.freeDiscards);
    const discardedCities = useAppSelector((state) => state.booking.discardedCities.length);
    const discardedWeekend = useAppSelector((state) => state.booking.discardedWeekend);
    const promoCode = useAppSelector((state) => state.discount);
    const accommodation = useAppSelector((state) => state.extras.accommodation);
    const breakfast = useAppSelector((state) => state.extras.breakfast);
    const seatsTogether = useAppSelector((state) => state.extras.seatsTogether);
    const smallBaggagesNumber = useAppSelector((state) => state.extras.baggage.small);
    const bigBaggagesNumber = useAppSelector((state) => state.extras.baggage.big);
    const departureStartHour = useAppSelector((state) => state.extras.departureStartHour);
    const departureEndHour = useAppSelector((state) => state.extras.departureEndHour);
    const arrivalStartHour = useAppSelector((state) => state.extras.arrivalStartHour);
    const arrivalEndHour = useAppSelector((state) => state.extras.arrivalEndHour);
    const insurances = useAppSelector((state) => state.extras.insurances) ?? [];
    /*     const cancellationInsurance = useAppSelector((state) => state.extras.insurances);
    const travelInsurance = useAppSelector((state) => state.extras.travelInsurance); */
    const currencyExchangeRate = useAppSelector((state) => state.booking.currency.exchangeRate) || defaultExchangeRate;
    const { logPrices, travellingAlonePrice, discardDestinationPrice, discardWeekendPrice } = useAppSelector(
        (state) => state.plan.prices,
    );

    // TODO: Cambiar todos los slugs hardcodeados por los equivalentes en el enum de AccommodationSlug
    // ---------------------------------------------------------------------------------------------
    const planAvailableExtras = useAppSelector((state) => state.plan.availableExtras);
    const generalAccommodation = planAvailableExtras?.find((extra: any) => extra.slug === 'accommodation');
    const getawayAccommodation = planAvailableExtras?.find((extra: any) => extra.slug === 'accommodation-getaway');
    const planAccommodation = isGetawayPlan ? getawayAccommodation : generalAccommodation;
    const planAccommodationOptions = planAccommodation?.options;
    const planBreakfast = planAvailableExtras?.find((extra: any) => extra.slug === 'breakfast');
    const planSeatsTogether = planAvailableExtras?.find((extra: any) => extra.slug === 'seats-together');
    const planBaggage10k = planAvailableExtras?.find((extra: any) => extra.slug === 'baggage-10');
    const planBaggage20k = planAvailableExtras?.find((extra: any) => extra.slug === 'baggage-20');
    /*     const planTravelInsurance = planAvailableExtras?.find((extra: any) => extra.slug === ExtraSlug.travelInsurance);
    const planCancellationInsurance = planAvailableExtras?.find(
        (extra: any) => extra.slug === ExtraSlug.cancellationInsurance,
    ); */
    const planTravelCancelInsurance = planAvailableExtras?.find(
        (extra: any) => extra.slug === ExtraSlug.travelCancelInsurance,
    );
    const planTravelCancelInsurancePlus = planAvailableExtras?.find(
        (extra: any) => extra.slug === ExtraSlug.travelCancelInsurancePlus,
    );

    const planDepartureHour = planAvailableExtras?.find((extra: any) => extra.slug === 'outbound-flight');
    const planhotelStandardInfo = planAccommodationOptions?.find((acco: any) => acco.slug === 'hotel');
    const planhotelGetawayInfo = planAccommodationOptions?.find((item: any) => item.slug === 'hotel-getaway');
    const planhotelSuperiorInfo = planAccommodationOptions?.find((acco: any) => acco.slug === 'hotel-breakfast');
    const planApartamentInfo = planAccommodationOptions?.find((acco: any) => acco.slug === 'apartment');
    const planExclusiveRoomsInfo = planAccommodationOptions?.find(
        (acco: any) => acco.slug === AccommodationSlug.exclusiveRooms,
    );
    const planBnbInfo = planAccommodationOptions?.find((acco: any) => acco.slug === 'bnb');
    const planApartmentGetawayInfo = planAccommodationOptions?.find(
        (item: any) => item.slug === AccommodationSlug.apartmentGetaway,
    );
    const planRuralInfo = planAccommodationOptions?.find((acco: any) => acco.slug === 'rural-house');

    const smallBaggagePrice = Number(planBaggage10k?.price) || 0;
    const bigBaggagePrice = Number(planBaggage20k?.price) || 0;
    const changeFlightHourPrice = Number(planDepartureHour?.price) || 0;
    /*     const cancellationInsurancePercentage = Number(planCancellationInsurance?.percentage) || 0;
    const travelInsurancePrice = Number(planTravelInsurance?.price) || 0; */
    const travelCancelInsurancePrice = Number(planTravelCancelInsurance?.price) || 0;
    const travelCancelInsurancePlusPrice = Number(planTravelCancelInsurancePlus?.price) || 0;
    const hotelGetawayPrice = Number(planhotelGetawayInfo?.price) || 0;
    const hotelStandardPrice = Number(planhotelStandardInfo?.price) || 0;
    const hotelSuperiorPrice = Number(planhotelSuperiorInfo?.price) || 0;
    const apartmentPrice = Number(planApartamentInfo?.price) || 0;
    const apartmentGetawayPrice = Number(planApartmentGetawayInfo?.price) || 0;
    const exclusiveRoomsPrice = Number(planExclusiveRoomsInfo?.price) || 0;
    const ruralPrice = Number(planRuralInfo?.price) || 0;
    const bnbPrice = Number(planBnbInfo?.price) || 0;

    const breakfastPricePerDay = Number(planBreakfast?.extra_day_price) || 0;
    const seatsTogetherPrice = Number(planSeatsTogether?.price) || 0;
    const hotelStandardExtraDayPrice = Number(planhotelStandardInfo?.extra_day_price) || 0;
    const hotelGetawayExtraDayPrice = Number(planhotelGetawayInfo?.extra_day_price) || 0;
    const hotelSuperiorExtraDayPrice = Number(planhotelSuperiorInfo?.extra_day_price) || 0;
    const apartmentExtraDayPrice = Number(planApartamentInfo?.extra_day_price) || 0;
    const apartmentGetawayExtraDayPrice = Number(planApartmentGetawayInfo?.extra_day_price) || 0;
    const exclusiveRoomsExtraDayPrice = Number(planExclusiveRoomsInfo?.extra_day_price) || 0;
    const ruralExtraDayPrice = Number(planRuralInfo?.extra_day_price) || 0;
    const bnbExtraDayPrice = Number(planBnbInfo?.extra_day_price) || 0;

    // Aloy flights and hotels
    const planAvailableFlights = useAppSelector((state) => state.plan.availableFlights);
    const planAvailableHotelsOffers = useAppSelector((state) => state.plan.availableHotelRooms);
    const selectedFlightInfo = planAvailableFlights?.find((flight: FlightOffer) => flight.offerId === selectedFlightId);
    const selectedHotelOfferInfo = planAvailableHotelsOffers?.find(
        (hotel: HotelOffer) => hotel.offerId === selectedHotelOfferId,
    );

    function getFlightOfferExtraPrice() {
        const flightExtraPrice = selectedFlightInfo?.relativePrice || 0;
        return flightExtraPrice;
    }

    function getHotelOfferExtraPrice() {
        const hotelExtraPrice = selectedHotelOfferInfo?.relativePrice || 0;
        return hotelExtraPrice;
    }

    function getFlightsNumber() {
        let flightsNumber = 2;

        if (selectedPlanSlug === 'multidestination') {
            flightsNumber = (2 + (days - 1)) / 2;
        }

        return flightsNumber;
    }

    function getTravellingAlonePrice() {
        if (isAloyPlan) return 0;
        if (totalTravellers > 1) return 0;

        return Math.round(Number(travellingAlonePrice / currencyExchangeRate));
    }

    function getDiscardDestinationsPricePerAdult() {
        if (discardedCities <= freeAvailableDiscards) return 0;

        const aditionalDiscardedCities = discardedCities - freeAvailableDiscards;
        const discardsPricePerAdult = aditionalDiscardedCities * discardDestinationPrice;

        return discardsPricePerAdult;
    }

    function getDiscardWeekendPerAdult() {
        if (discardedWeekend === '') return 0;
        return discardWeekendPrice;
    }

    function getAccomodationPricePerAdult(slug: string = accommodation.slug) {
        const isHotel = slug === AccommodationSlug.hotel;
        const isHotelGetaway = slug === AccommodationSlug.hotelGetaway;
        const isApartment = slug === AccommodationSlug.apartment;
        const isApartmentGetaway = slug === AccommodationSlug.apartmentGetaway;
        const isHotelSuperior = slug === AccommodationSlug.hotelBreakfast;
        const isExclusiveRooms = slug === AccommodationSlug.exclusiveRooms;
        const isRural = slug === AccommodationSlug.ruralHouse;
        const isBnb = slug === AccommodationSlug.bnb;

        if (days > 3) {
            const extraDays = days - 3;

            if (isHotel && !isGetawayPlan) return hotelStandardPrice + hotelStandardExtraDayPrice * extraDays;
            if (isHotelGetaway && isGetawayPlan) return hotelGetawayPrice + hotelGetawayExtraDayPrice * extraDays;
            if (isApartment) return apartmentPrice + apartmentExtraDayPrice * extraDays;
            if (isApartmentGetaway) return apartmentGetawayPrice + apartmentGetawayExtraDayPrice * extraDays;
            if (isHotelSuperior) return hotelSuperiorPrice + hotelSuperiorExtraDayPrice * extraDays;
            if (isExclusiveRooms) return exclusiveRoomsPrice + exclusiveRoomsExtraDayPrice * extraDays;
            if (isRural) return ruralPrice + ruralExtraDayPrice * extraDays;
            if (isBnb) return bnbPrice + bnbExtraDayPrice * extraDays;
        }

        if (isHotel && !isGetawayPlan) return hotelStandardPrice;
        if (isHotelGetaway && isGetawayPlan) return hotelGetawayPrice;
        if (isApartment) return apartmentPrice;
        if (isApartmentGetaway) return apartmentGetawayPrice;
        if (isHotelSuperior) return hotelSuperiorPrice;
        if (isExclusiveRooms) return exclusiveRoomsPrice;
        if (isRural) return ruralPrice;
        if (isBnb) return bnbPrice;

        return 0;
    }

    function getBreakfastPricePerAdult() {
        if (!breakfast) return 0;
        const breakfastPricePerAdult = breakfastPricePerDay * days;

        return breakfastPricePerAdult;
    }

    function getSeatsTogetherPricePerAdult() {
        if (!seatsTogether) return 0;

        const flightsNumber = getFlightsNumber();
        const seatsTogetherPricePerAdult = seatsTogetherPrice * flightsNumber;

        return seatsTogetherPricePerAdult;
    }

    function getBaggagesPrice() {
        const flightsNumber = getFlightsNumber();

        const smallBaggagesPrice = smallBaggagesNumber * smallBaggagePrice * flightsNumber;
        const bigBaggagesPrice = bigBaggagesNumber * bigBaggagePrice * flightsNumber;

        const totalBaggagesPrice = smallBaggagesPrice + bigBaggagesPrice;

        return totalBaggagesPrice ?? 0;
    }

    const getDepartureChangesPrice = () => {
        if (departureEndHour - departureStartHour === 12) return changeFlightHourPrice;
        if (departureEndHour - departureStartHour === 6) return changeFlightHourPrice * 2;

        return 0;
    };

    const getArrivalChangesPrice = () => {
        if (arrivalEndHour - arrivalStartHour === 12) return changeFlightHourPrice;
        if (arrivalEndHour - arrivalStartHour === 6) return changeFlightHourPrice * 2;

        return 0;
    };

    const departureChangesPrice = getDepartureChangesPrice();
    const arrivalChangesPrice = getArrivalChangesPrice();

    function getFlightHoursChangesPricePerAdult() {
        const flightHoursChangesPrice = departureChangesPrice + arrivalChangesPrice;

        return flightHoursChangesPrice;
    }

    const accommodationPricePerAdult = getAccomodationPricePerAdult();
    const breakfastPricePerAdult = getBreakfastPricePerAdult();
    const seatsTogetherPricePerAdult = getSeatsTogetherPricePerAdult();
    const flightHoursChangesPrice = getFlightHoursChangesPricePerAdult();

    function getExtrasPricePerAdult() {
        const pricePerAdult =
            accommodationPricePerAdult + breakfastPricePerAdult + seatsTogetherPricePerAdult + flightHoursChangesPrice;

        return pricePerAdult;
    }

    /*     function getTravelInsuranceBasePrice() {
        const isSelected = insurances?.find((insurance) => insurance.slug === ExtraSlug.travelInsurance);
        if (!isSelected) return 0;

        return travelInsurancePrice;
    } */

    function getInsurancePricePerPerson() {
        const hasInsurance = insurances?.length > 0;
        if (!hasInsurance) return 0;

        const selectedInsurancePrice = planAvailableExtras.find((extra: any) => extra.id === insurances[0]?.id)?.price;
        if (!selectedInsurancePrice) return 0;

        return selectedInsurancePrice;
    }

    function getTravelCancelInsurancePrice() {
        const isSelected = insurances?.find((insurance) => insurance.slug === ExtraSlug.travelCancelInsurance);
        if (!isSelected) return 0;

        return travelCancelInsurancePrice * totalTravellers;
    }

    const getTravelCancelInsurancePlusPrice = () => {
        const isSelected = insurances?.find((insurance) => insurance.slug === ExtraSlug.travelCancelInsurancePlus);
        if (!isSelected) return 0;

        return travelCancelInsurancePlusPrice * totalTravellers;
    };

    /*     function getTravelInsurancePerPerson() {
        const travelInsuranceBasePrice = getTravelInsuranceBasePrice();

        return travelInsuranceBasePrice;
    } */

    function getTravelInsuranceTotalPrice() {
        const pricePerPerson = getInsurancePricePerPerson();

        const pricePerAdults = pricePerPerson * adultsNumber;
        const pricePerChildren = (pricePerPerson - childrenDiscount * pricePerPerson) * childrenNumber;
        const pricePerBabies = pricePerPerson * babiesNumber;

        const totalTravelInsurance = pricePerAdults + pricePerChildren + pricePerBabies;

        return totalTravelInsurance;
    }

    /*     function getCancellationInsurancePrice(price: number) {
        const isSelected = insurances?.find((insurance) => insurance.slug === ExtraSlug.cancellationInsurance);
        if (!isSelected) return 0;

        const cancellationInsurancePrice = (cancellationInsurancePercentage / 100) * price;
        return cancellationInsurancePrice;
    } */

    const travellingAloneSuplement = getTravellingAlonePrice();
    const discardDestinationsPricePerAdult = getDiscardDestinationsPricePerAdult();

    function getBookingPricePerAdult() {
        const discardWeekendPricePerAdult = getDiscardWeekendPerAdult();
        const extrasPricePerAdult = getExtrasPricePerAdult();

        const bookingPricePerAdult =
            basePricePerPerson +
            travellingAloneSuplement +
            discardDestinationsPricePerAdult +
            discardWeekendPricePerAdult +
            extrasPricePerAdult;

        if (bookingPricePerAdult < 0) return 0;
        return bookingPricePerAdult;
    }

    function getBookingPricePerChildren(pricePerAdult: number) {
        return pricePerAdult - childrenDiscount * pricePerAdult;
    }

    function getBookingPricePerBaby() {
        return Math.round(Number(babyPrice / currencyExchangeRate));
    }

    function getDiscountQuantity(price: number) {
        if (!promoCode.existingDiscount) return 0;

        const { discountType } = promoCode;
        const discountAmount = Number(promoCode.discountAmount);

        if (discountType === 'amount') return discountAmount;

        const discountQuantity = (discountAmount / 100) * price;

        return Number(discountQuantity.toFixed(5));
    }

    function getTotalTravelPrice(totalBookingPrice: number, discountQuantity: number) {
        const totalTravelPrice = totalBookingPrice - discountQuantity;

        if (totalTravelPrice < 0) return 0;
        const roundedTotalPrice = (Math.round(totalTravelPrice * 100) / 100).toFixed(2);

        return Number(roundedTotalPrice);
    }

    function getFunnelPricePerAdult() {
        if (isAloyPlan) {
            return Number((totalBookingPrice / totalTravellers).toFixed(2));
        }

        const travelInsurancePricePerPerson = getInsurancePricePerPerson();
        /* const travelInsuranceAdultsPrice = travelInsurancePricePerPerson; */
        /* const baggagesPricePerAdult = baggagesPrice / adultsNumber; */
        /*         const cancellationInsuranceAdultPrice = getCancellationInsurancePrice(
            totalPricePerAdult + travelInsuranceBasePrice + baggagesPricePerAdult,
        ); */

        const extrasPrice = baggagesPrice + travelInsurancePricePerPerson * adultsNumber;
        const pricePerPerson = (totalPricePerAdult + extrasPrice / adultsNumber).toFixed(2);

        const roundPrice = Math.round(Number(pricePerPerson) * 100) / 100;

        return roundPrice;
    }

    function getFunnelPricePerAdultWithDiscount() {
        const funnelPricePerAdult = getFunnelPricePerAdult();
        const isPercentage = promoCode.discountType === DiscountType.Percentage;
        const discount = promoCode.discountAmount;
        let newPricePerAdult = 0;

        if (isPercentage) {
            newPricePerAdult = funnelPricePerAdult - (funnelPricePerAdult / 100) * discount;
        } else {
            newPricePerAdult = funnelPricePerAdult - discount / (adultsNumber + childrenNumber);
        }

        const roundPrice = Math.round(Number(newPricePerAdult) * 100) / 100;
        const priceToShow = roundPrice < 0 ? 0 : roundPrice;

        return priceToShow;
    }

    // Precios totales por tipo de pasajero
    const totalPricePerAdult = getBookingPricePerAdult();
    const totalPricePerChildren = getBookingPricePerChildren(totalPricePerAdult);
    const totalPricePerBaby = getBookingPricePerBaby();

    // Precios totales por todos los pasajeros de cada tipo
    const totalPriceAllAdults = totalPricePerAdult * adultsNumber;
    const totalPriceAllChildren = totalPricePerChildren * childrenNumber;
    const totalPriceAllBabies = totalPricePerBaby * babiesNumber;

    // Precios total por todos los pasajeros
    const allTravellersPrice = totalPriceAllAdults + totalPriceAllChildren + totalPriceAllBabies;

    // Precios de hotel y vuelo (solo para Aloy)
    const flightOfferExtraPrice = getFlightOfferExtraPrice();
    const hotelOfferExtraPrice = getHotelOfferExtraPrice();

    const baggagesPrice = getBaggagesPrice();
    const travelInsuranceBasePrice = getInsurancePricePerPerson();
    const travelInsuranceTotalPrice = getTravelInsuranceTotalPrice();
    /*     const cancellationInsurancePrice = getCancellationInsurancePrice(
        allTravellersPrice + travelInsuranceTotalPrice + baggagesPrice,
    ); */

    const flykubeTotalBookingPrice = allTravellersPrice + baggagesPrice + travelInsuranceTotalPrice;

    const aloyTotalBookingPrice =
        aloyTotalPrice +
        baggagesPrice +
        flightOfferExtraPrice +
        hotelOfferExtraPrice +
        getTravelCancelInsurancePrice() +
        getTravelCancelInsurancePlusPrice();

    const totalBookingPrice = isAloyPlan ? aloyTotalBookingPrice : flykubeTotalBookingPrice;
    const discountQuantity = getDiscountQuantity(totalBookingPrice);
    const totalTravelPrice = getTotalTravelPrice(totalBookingPrice, discountQuantity);

    function logPricesInConsole() {
        console.clear();

        const bookingPricePerAdult = getBookingPricePerAdult();
        const pricesForConsoleLog = {
            basePricePerAdult: basePricePerPerson,
            travellingAloneSuplement,
            discardDestinationsPricePerAdult,
            discardWeekendPricePerAdult: getDiscardWeekendPerAdult(),
            accommodationPricePerAdult,
            breakfastPricePerAdult,
            flightHoursChangesPrice,
            bookingPricePerAdult,
            bookingPricePerChildren: childrenNumber > 0 ? Number(totalPricePerChildren.toFixed(3)) : 0,
            bookingPricePerBaby: babiesNumber > 0 ? Number(totalPricePerBaby.toFixed(3)) : 0,
            bookingPriceAllTravellers: Number(allTravellersPrice.toFixed(3)),
            baggagesPrice: getBaggagesPrice(),
            travelInsuranceAllTravellers: travelInsuranceTotalPrice,
            discountQuantity,
            totalTravelPrice: Number(totalTravelPrice.toFixed(3)),
            lastItem: '.',
        };

        console.table(pricesForConsoleLog);
    }

    if (logPrices) logPricesInConsole();

    return {
        baggagesPrice,
        totalPricePerAdult,
        totalPricePerChildren,
        totalPricePerBaby,
        totalBookingPrice,
        totalTravelPrice,
        allTravellersPrice,
        discardDestinationsPricePerAdult,
        accommodationPricePerAdult,
        breakfastPricePerAdult,
        departureChangesPrice,
        arrivalChangesPrice,
        discountQuantity,
        travelInsuranceBasePrice,
        travelInsuranceTotalPrice,
        travelCancelInsurancePrice,
        travelCancelInsurancePlusPrice,
        getFunnelPricePerAdult,
        getFunnelPricePerAdultWithDiscount,
        getBookingPricePerBaby,
        getTravellingAlonePrice,
        getAccomodationPricePerAdult,
        getHotelOfferExtraPrice,
        getFlightOfferExtraPrice,
        getTravelCancelInsurancePrice,
        getTravelCancelInsurancePlusPrice,
    };
};

export default usePrices;
