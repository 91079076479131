/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-var */
import { useEffect, useState } from 'react';
import whatsappBubbleIcon from '@/assets/icons/whatsapp-bubble.svg';
import useRoutes from './useRoutes';

/* const useChatWidget = () => {
    const { currentPathname, isRouteWithChat } = useRoutes();
    const chatBubble = document.querySelector('#fc_frame') as any;

    useEffect(() => {
        if (!chatBubble) return;

        if (isRouteWithChat) {
            chatBubble.style.display = 'initial';
        } else {
            chatBubble.style.display = 'none';
        }
    }, [currentPathname]);
}; */

/* declare global {
    interface Window {
        attachEvent: any;
        fcSettings: any;
        fcWidget?: any;
    }
}

const initFreshChat = () => {
    window.fcWidget?.init({
        token: '0862a4c5-e753-465a-81e0-d8cf18ae2c6c',
        host: 'https://wchat.eu.freshchat.com',
    });
};

const initialize = (i: Document, t: string) => {
    var e;
    i.getElementById(t)
        ? initFreshChat()
        : (((e = i.createElement('script')).id = t),
          (e.async = !0),
          (e.src = 'https://wchat.eu.freshchat.com/js/widget.js'),
          (e.onload = initFreshChat),
          i.head.appendChild(e));
};

const useChatWidget = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const { currentPathname, isRouteWithChat } = useRoutes();

    useEffect(() => {
        setTimeout(() => {
            initiateChatWidget();
        }, 10000);
    }, []);

    useEffect(() => {
        if (!isRouteWithChat) {
            window?.fcWidget?.hide();
        } else {
            window?.fcWidget?.show();
        }
    }, [currentPathname, isLoaded]);

    const initiateChatWidget = () => {
        initialize(document, 'Freshdesk Messaging-js-sdk');

        const targetNode = document.head;
        const config = { attributes: false, childList: true, subtree: false };

        const callback = (mutationList: MutationRecord[], observer: MutationObserver) => {
            mutationList.forEach((mutation) => {
                const firstNode = mutation.addedNodes[0] as any;
                const isChatWidget = firstNode?.href?.includes('freshchat');

                if (isChatWidget) {
                    setIsLoaded(true);
                    observer.disconnect();
                }
            });
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, config);
    };
}; */

const useChatWidget = () => {
    const [showWidget, setShowWidget] = useState(false);
    const { currentPathname, isRouteWithChat } = useRoutes();

    useEffect(() => {
        if (!isRouteWithChat) {
            setShowWidget(false);
        } else {
            setShowWidget(true);
        }
    }, [currentPathname, showWidget]);

    const onButtonClick = () => {
        window.open('https://wa.me/+393314047147', '_blank', 'noopener,noreferrer');
    };

    const WhatsappWidget = () => {
        return (
            <button
                onClick={onButtonClick}
                style={{
                    position: 'fixed',
                    bottom: '30px',
                    right: '30px',
                    zIndex: '10',
                    filter: 'drop-shadow(0px 1px 5px #c4c4c4)',
                }}
            >
                <img
                    src={whatsappBubbleIcon}
                    style={{ width: '70px', height: 'auto', aspectRatio: '1 / 1' }}
                    alt="Whatsapp"
                />
            </button>
        );
    };

    return { WhatsappWidget, showWidget };
};

export default useChatWidget;
