/* eslint-disable */
import { Nationality, PhoneCountryCode, TravellerGroup } from '@/types/types';
import { createSlice } from '@reduxjs/toolkit';

export interface Traveller {
    id?: number;
    maleOrFemale: number;
    name: string;
    surname: string;
    birthday: string[];
    nationality: Nationality;
    passportOrDoc: number;
    passportOrDocNum: string;
    passportOrDocExpDate: string[];
    isSurprise?: boolean;
    phoneCountryCode?: PhoneCountryCode;
    phone?: string;
    email?: string;
    hasPassport?: boolean;
    group?: 0 | 1 | 2;
}

export const travellerInfo: Traveller = {
    maleOrFemale: 0,
    name: '',
    surname: '',
    birthday: ['', '', ''],
    nationality: { code: '', name: '' },
    passportOrDoc: 1,
    passportOrDocNum: '',
    passportOrDocExpDate: ['', '', ''],
    hasPassport: false,
};

export const adultTravellerInfo: Traveller = {
    ...travellerInfo,
    isSurprise: false,
    phoneCountryCode: { number: 34, name: 'ES +34' },
    phone: '',
    email: '',
};

export type TravellersSliceState = {
    [key: string]: any;
    adults: Traveller[];
    children: Traveller[];
    babies: Traveller[];
    comments: string;
};

export const travellersInitialState: TravellersSliceState = {
    adults: [adultTravellerInfo, adultTravellerInfo],
    children: [],
    babies: [],
    comments: '',
};

export const travellersSlice = createSlice({
    name: 'travellers',
    initialState: travellersInitialState,
    reducers: {
        addTraveller: (state, action) => {
            const type = action.payload;
            const isAdult = type === TravellerGroup.adults;
            const travellerInfoToAdd = isAdult ? adultTravellerInfo : travellerInfo;

            state[type].push(travellerInfoToAdd);
        },
        removeTraveller: (state, action) => {
            const type = action.payload;
            state[type].pop();
        },
        setTravellers: (state, action) => {
            state.adults = [];
            state.children = [];
            state.babies = [];

            for (let i = 0; i < action.payload.adults; i++) {
                state.adults.push(adultTravellerInfo);
            }
            for (let i = 0; i < action.payload.children; i++) {
                state.children.push(travellerInfo);
            }
            for (let i = 0; i < action.payload.babies; i++) {
                state.babies.push(travellerInfo);
            }
        },
        setTravellersInfo: (state, action) => {
            const adultsInfo = action.payload.adultsInfo;
            const childrenInfo = action.payload.childrenInfo;
            const babiesInfo = action.payload.babiesInfo;

            state.adults = adultsInfo;
            state.children = childrenInfo;
            state.babies = babiesInfo;
        },
        setIsSurprise: (state, action) => {
            const { id, value } = action.payload;
            state.adults[id].isSurprise = value;
        },
        setGender: (state, action) => {
            const { type, position, value } = action.payload;
            state[type][position].maleOrFemale = value;
        },
        setName: (state, action) => {
            const { type, position, name } = action.payload;
            state[type][position].name = name;
        },
        setSurname: (state, action) => {
            const { type, position, surname } = action.payload;
            state[type][position].surname = surname;
        },
        setBirthday: (state, action) => {
            const { type, position, category, value } = action.payload;
            state[type][position].birthday[category] = value;
        },
        setNationality: (state, action) => {
            const { type, position, nationality } = action.payload;
            state[type][position].nationality = { code: nationality.code, name: nationality.name };
        },
        setPassportOrDoc: (state, action) => {
            const { type, position, value } = action.payload;
            state[type][position].passportOrDoc = value;
        },
        setPassportOrDocNum: (state, action) => {
            const { type, position, idNum } = action.payload;
            state[type][position].passportOrDocNum = idNum;
        },
        setPassportOrDocExpDate: (state, action) => {
            const { type, position, category, value } = action.payload;
            state[type][position].passportOrDocExpDate[category] = value;
        },
        setPhoneCountryCode: (state, action) => {
            const { position, countryCode } = action.payload;
            state.adults[position].phoneCountryCode = { number: countryCode.number, name: countryCode.name };
        },
        setPhoneNumber: (state, action) => {
            const { position, number } = action.payload;
            state.adults[position].phone = number;
        },
        setEmail: (state, action) => {
            const { position, email } = action.payload;
            state.adults[position].email = email;
        },
        setHasPassport: (state, action) => {
            const { type, position, hasPassport } = action.payload;
            state[type][position].hasPassport = hasPassport;
        },
        setComments: (state, action) => {
            state.comments = action.payload;
        },
        resetTravellersStates: () => {
            return { ...travellersInitialState };
        },
    },
});

export const {
    addTraveller,
    removeTraveller,
    setTravellers,
    setTravellersInfo,
    setIsSurprise,
    setGender,
    setName,
    setSurname,
    setBirthday,
    setNationality,
    setPassportOrDoc,
    setPassportOrDocNum,
    setPassportOrDocExpDate,
    setPhoneCountryCode,
    setPhoneNumber,
    setEmail,
    setHasPassport,
    setComments,
    resetTravellersStates,
} = travellersSlice.actions;

export default travellersSlice.reducer;
