import { hotelAmenitiesSample, roomAmenitiesSample } from '@/database/samples/amenities';
import roomDefaultImage from '@/assets/images/room-default.jpg';
import { BedGroup, HotelOffer } from '@/types/types';

export interface RateOfferFromAPI {
    id: number;
    room: HotelRoomFromAPI;
    refundable: boolean;
    relative_price: number;
    price_type: null;
    inclusive_price_value: string;
    inclusive_price_currency: string;
    total_type: null;
    total_value: null;
    total_currency: null;
    target_currency: string;
    fees: any;
    confirm_link: null;
    bed_groups: { [key: string]: BedGroupFromAPI | undefined };
    exclusive_price_value: string;
    exclusive_price_currency: string;
    strikethrough_price_value: string;
    strikethrough_price_currency: string | null;
    marketing_fee_value: string;
    marketing_fee_currency: string;
    gross_profit_value: string;
    gross_profit_currency: string;
    minimum_selling_price_value: string;
    minimum_selling_price_currency: null;
    property_fees_value: string;
    property_fees_currency: string | null;
    itinerary_id: null;
    retrieve_link: null;
    occupancies: string[];
}

export interface HotelRoomFromAPI {
    id: number;
    amenities: Amenity[];
    translations: { [key: string]: Translation };
    hotel: HotelInfoFromAPI;
    room_id: string;
    characteristicCode: null;
    images: HotelImage[];
    area: number | null;
    max_occupancy: null;
    minimum_age: null;
    occupancy: null;
}

export interface HotelInfoFromAPI {
    id: number;
    city: HotelCity;
    amenities: Amenity[];
    ratings: { [key: string]: number | null }[];
    translations: { [key: string]: Translation };
    type: string;
    source: string;
    hb: boolean;
    hb_code: null;
    hb_category_code: null;
    hb_ranking: null;
    hb_images: null;
    latitude: number;
    longitude: number;
    website: null;
    code: string;
    name: string;
    stars: number;
    phone_country_code: null;
    phone: string;
    address: string;
    email: null;
    description: null;
    image: null;
    active: boolean;
    distance: number;
    images: HotelImage[];
    expedia_rank: null;
    last_update: string;
}

export interface Amenity {
    expedia_id: number;
    category: string;
    value: null;
    translations: { [key: string]: string | undefined };
}

export interface HotelCity {
    id: number;
    iata_code: string;
    image: string | null;
    name: string;
}

interface HotelImage {
    links: { [key: string]: PriceCheck };
    caption: string;
    category: number;
    hero_image: boolean;
}

interface BedGroupFromAPI {
    id: string;
    links: Links;
    description: string;
    configuration: BedConfiguration[];
}

interface BedConfiguration {
    size: string;
    type: string;
    quantity: number;
}

interface Links {
    price_check: PriceCheck;
}

interface PriceCheck {
    href: string;
    method: string;
}

interface Translation {
    bed_groups: null;
    name: string;
    description: string;
}

const availableIcons = [
    'accessibility_bathroom',
    'accessibility_parking',
    'accessibility',
    'air_conditioning',
    'airport_transfer',
    'all_inclusive',
    'bar',
    'casino',
    'crib',
    'drycleaning_and_laundry_services',
    'dryer',
    'electric_charging_station',
    'free_airport_transportation',
    'free_all_meals',
    'free_breakfast_1_or_2_guests',
    'free_breakfast',
    'free_dinner',
    'free_lunch',
    'free_wifi',
    'gym',
    'hairdryer',
    'hot_tub',
    'kitchen',
    'meeting_facility',
    'ocean_view',
    'parking',
    'pets_allowed',
    'restaurant_in_hotel',
    'spa_services',
    'swimming_pool',
    'water_park',
    'weelchair_accessibility',
    'wifi',
];

const defaultRoom = {
    medium: roomDefaultImage,
    large: roomDefaultImage,
    description: '',
    isHeroImage: true,
};

const getImages = (imagesList: HotelImage[]) => {
    if (!imagesList) return [defaultRoom];
    const images = imagesList.map((image) => {
        return {
            medium: image.links['350px']?.href,
            large: image.links['1000px']?.href,
            description: image?.caption ?? '',
            isHeroImage: image?.hero_image ?? false,
        };
    });

    return images.slice(0, 15);
};

const getBedGroups = (bedGroups: { [key: string]: BedGroupFromAPI | undefined }): BedGroup[] => {
    if (!bedGroups) return [];
    const bedGroupsArray = Object.values(bedGroups).map((bedGroup) => {
        return {
            id: Number(bedGroup?.id) ?? 0,
            description: bedGroup?.description ?? '',
        };
    });

    return bedGroupsArray;
};

const getAmenities = (amenitiesList: Amenity[], locale: string) => {
    if (!amenitiesList) return [];

    const adaptedAmenities = amenitiesList.map((amenity) => {
        const localeDesc = amenity.translations?.[locale];
        const engDesc = amenity.translations?.en;
        const serviceDescription = localeDesc ?? engDesc ?? '';

        return {
            id: amenity.expedia_id,
            slug: amenity.category,
            description: serviceDescription,
            hasIcon: availableIcons.includes(amenity.category),
        };
    });

    const amenitiesWithSlugsFrist = [...adaptedAmenities].sort((a, b) => {
        if (a.hasIcon && !b.hasIcon) return -1;
        if (!a.hasIcon && b.hasIcon) return 1;
        return 0;
    });

    return amenitiesWithSlugsFrist.slice(0, 10);
};

const getDistanceFromCenter = (distance: string | undefined) => {
    if (!distance) return 0;

    const distanceNumber = Number(distance);
    return Number((distanceNumber / 1000).toFixed(1));
};

const removeWordsInRoomNames = (roomName: string | undefined) => {
    const wordsToRemove = [', NonRefundable'];

    if (!roomName) return '';
    const cleanName = wordsToRemove.reduce((acc, word) => {
        return acc.replace(word, '');
    }, roomName);

    return cleanName;
};

export const adaptHotelOffers = (rateOffers: RateOfferFromAPI[], locale: string = 'en'): HotelOffer[] => {
    const adaptedHotels = rateOffers.map((rateOffer) => {
        const hotel = rateOffer?.room?.hotel ?? {};
        const hotelImages = getImages(hotel?.images ?? []);
        const roomImages = getImages(rateOffer?.room?.images);
        const bedGroups = getBedGroups(rateOffer?.bed_groups);
        const roomName = Object.values(rateOffer?.room?.translations ?? [])?.[0]?.name;
        const cleanRoomName = removeWordsInRoomNames(roomName);
        const hotelServices = getAmenities(hotel?.amenities, locale);
        const roomExtras = getAmenities(rateOffer?.room.amenities, locale);
        /* const hotelServices = getAmenities(hotelAmenitiesSample, locale);
        const roomExtras = getAmenities(roomAmenitiesSample, locale); */

        return {
            offerId: rateOffer.id,
            hotelId: Number(hotel.id),
            hotelCode: hotel.code ?? '',
            name: hotel.name ?? '',
            description: hotel.description ?? '',
            stars: Number(hotel.stars),
            rate: Number(hotel?.ratings?.[0]?.guest_overall ?? 0),
            images: hotelImages,
            services: hotelServices,
            address: hotel.address ?? '',
            coordinates: [Number(hotel.latitude), Number(hotel.longitude)] as [number, number],
            room: {
                name: cleanRoomName ?? '',
                images: roomImages,
                extras: roomExtras,
            },
            bedGroups,
            distanceFromCenter: getDistanceFromCenter(hotel.distance?.toString()),
            relativePrice: rateOffer.relative_price,
            currency: rateOffer?.target_currency ?? 'EUR',
            isRefundable: rateOffer?.refundable ?? null,
            occupancies: rateOffer?.occupancies ?? [],
        };
    });

    return adaptedHotels;
};

/* export const adaptHotels = (hotelsList: HotelInfoFromAPI[], locale = 'en'): HotelOffer[] => {
    const adaptedHotels = hotelsList.map((hotel) => {
        const hotelImages = getImages(hotel.images ?? []);
        const hotelServices = getAmenities(hotel.amenities, locale);

        return {
            offerId: null,
            hotelId: Number(hotel.id),
            hotelCode: hotel.code ?? '',
            name: hotel.name ?? '',
            description: hotel.description ?? '',
            stars: Number(hotel.stars),
            rate: Number(hotel.ratings[0]?.guest_overall),
            images: hotelImages,
            services: hotelServices,
            address: hotel.address ?? '',
            coordinates: [Number(hotel.latitude), Number(hotel.longitude)] as [number, number],
            room: {
                // Should be updated later
                name: '',
                images: [],
                extras: [],
            },
            bedGroups: [],
            distanceFromCenter: getDistanceFromCenter(hotel.distance.toString()),
            relativePrice: 0, // Should be updated later
            currency: '', // Should be updated later
            isRefundable: null, // Should be updated later
            occupancies: [], // Should be updated later
        };
    });

    return adaptedHotels;
}; */

export default adaptHotelOffers;
