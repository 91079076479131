/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export type DiscountSliceState = {
    existingDiscount: boolean;
    isOfferDiscount: boolean;
    discountCode: string;
    discountType: string;
    discountAmount: number;
};

const initialState: DiscountSliceState = {
    existingDiscount: false,
    isOfferDiscount: false,
    discountCode: '',
    discountType: '',
    discountAmount: 0,
};

export const bookingSlice = createSlice({
    name: 'discount',
    initialState,
    reducers: {
        setDiscount: (state, action) => {
            const { existingDiscount, discountCode, discountType, discountAmount } = action.payload;
            state.existingDiscount = existingDiscount;
            state.discountCode = discountCode.toUpperCase();
            state.discountType = discountType;
            state.discountAmount = Number(discountAmount);
        },
        setOfferDiscount: (state, action) => {
            state.isOfferDiscount = action.payload;
        },
        resetDiscountStates: () => {
            return { ...initialState };
        },
    },
});

export const { setDiscount, setOfferDiscount, resetDiscountStates } = bookingSlice.actions;

export default bookingSlice.reducer;
