import { useEffect } from 'react';
import useRoutes from '../../../hooks/useRoutes';

const ScrollToTop = () => {
    const { currentPathname, experiencesInfoRoutes } = useRoutes();
    const isPlanInfoPage = experiencesInfoRoutes.includes(currentPathname);

    useEffect(() => {
        if (isPlanInfoPage) return;
        window.scrollTo(0, 0);
    }, [currentPathname]);

    return null;
};

export default ScrollToTop;
