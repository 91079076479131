import { Plan } from '@/types/types';
import { IMAGES } from './plansImages';

/* prettier-ignore */
const plans: Plan[] = [
    {
        id: 1,
        active: true,
        slug: 'surprise_destination',
        title: 'surpriseDestination',
        subtitle: 'coverInfoText',
        summary: 'destinationShortSummary',
        colors: {
            default: '#fd3874',
            light: '#ff7854',
            dark: '#fd3874',
        },
        planIcon: IMAGES.surprisePlanIcon,
        cover: {
            large: IMAGES.destination,
            largeWebp: IMAGES.destinationWebp,
            small: IMAGES.destinationSmall,
            smallWebp: IMAGES.destinationSmallWebp,
        },
        card: {
            jpg: IMAGES.destinationCard,
            webp: IMAGES.destinationCardWebp,
            pack: IMAGES.destinationCardPack,
        },
        planFeatures: ['directBookFlights', 'bookStandardHotel', 'includesGuideLong', 'amazingAdventure'],
        order: 1,
    },
    {
        id: 2,
        active: false,
        slug: 'multidestination',
        title: 'surpriseMultidestination',
        subtitle: 'coverInfoTextMulti',
        summary: 'multidestinationShortSummary',
        colors: {
            default: '#4599fc',
            light: '#4599fc',
            dark: '#1e33c0',
        },
        planIcon: IMAGES.multiPlanIcon,
        cover: {
            large: IMAGES.multi,
            largeWebp: IMAGES.multiWebp,
            small: IMAGES.multiSmall,
            smallWebp: IMAGES.multiSmallWebp,
        },
        card: {
            jpg: IMAGES.multiCard,
            webp: IMAGES.multiCardWebp,
            pack: IMAGES.multiCardPack,
        },
        planFeatures: ['directBookFlights', 'destinationHotelGrade', 'aFewRoutes', 'multidestinationStayTime'],
        order: 100,
    },
    {
        id: 3,
        active: true,
        slug: 'surprise_getaway',
        title: 'surpriseGetaway',
        subtitle: 'coverInfoTextGetaway',
        summary: 'getawayShortSummary',
        colors: {
            default: '#62d065',
            light: '#62d065',
            dark: '#0ca379',
        },
        planIcon: IMAGES.getAwayPlanIcon,
        cover: {
            large: IMAGES.getaway,
            largeWebp: IMAGES.getawayWebp,
            small: IMAGES.getawaySmall,
            smallWebp: IMAGES.getawaySmallWebp,
        },
        card: {
            jpg: IMAGES.getawayCard,
            webp: IMAGES.getawayCardWebp,
            pack: IMAGES.getawayCardPack,
        },
        planFeatures: ['nearLodging', 'getawayRoute', 'amazingAdventure'],
        order: 3,
    },
    {
        id: 4,
        active: true,
        slug: 'surprise_summer',
        title: 'surpriseBeach',
        subtitle: 'coverInfoTextSummer',
        summary: 'beachShortSummary',
        colors: {
            default: '#5bdded',
            light: '#3af6ff',
            dark: '#20b7d4',
        },
        planIcon: IMAGES.summerPlanIcon,
        cover: {
            large: IMAGES.summer,
            largeWebp: IMAGES.summerWebp,
            small: IMAGES.summerSmall,
            smallWebp: IMAGES.summerSmallWebp,
        },
        card: {
            jpg: IMAGES.summerCard,
            webp: IMAGES.summerCardWebp,
            pack: IMAGES.summerCardPack,
        },
        planFeatures: ['directBookFlights', 'bookBeachHotel', 'includesGuideLong', 'amazingAdventure'],
        order: 2,
    },
    {
        id: 5,
        active: false,
        slug: 'all_surprise',
        title: 'surpriseAll',
        subtitle: 'coverInfoTextAll',
        summary: 'surpriseShortSummary',
        colors: {
            default: '#f5a503',
            light: '#ffcf01',
            dark: '#fab514',
        },
        planIcon: IMAGES.allPlanIcon,
        cover: {
            large: IMAGES.all,
            largeWebp: IMAGES.allWebp,
            small: IMAGES.allSmall,
            smallWebp: IMAGES.allSmallWebp,
        },
        card: {
            jpg: IMAGES.allCard,
            webp: IMAGES.allCardWebp,
            pack: '',
        },
        planFeatures: ['directBookFlights', 'bookStandardHotel', 'includesGuideLong', 'amazingAdventure'],
        order: 100,
    },
    {
        id: 6,
        active: false,
        slug: 'vipkube',
        title: 'surpriseVip',
        subtitle: 'coverInfoTextVip',
        summary: 'vipShortSummary',
        colors: {
            default: '#9948fd',
            light: '#ad69f8',
            dark: '#802aeb',
        },
        planIcon: IMAGES.vipPlanIcon,
        cover: {
            large: IMAGES.vip,
            largeWebp: IMAGES.vipWebp,
            small: IMAGES.vipSmall,
            smallWebp: IMAGES.vipSmallWebp,
        },
        card: {
            jpg: IMAGES.vipCard,
            webp: IMAGES.vipCardWebp,
            pack: IMAGES.vipCardPack,
        },
        planFeatures: ['directBookFlights', 'bookVipHotel', 'includesGuideLong', 'amazingAdventure'],
        order: 100,
    },
    {
        id: 7,
        active: true,
        slug: 'national',
        title: 'nationalExperience',
        subtitle: 'coverInfoTextNational',
        summary: 'nationalShortSummary',
        colors: {
            default: '#f5a503',
            light: '#ffcf01',
            dark: '#fab514',
        },
        planIcon: IMAGES.nationalPlanIcon,
        cover: {
            large: IMAGES.national,
            largeWebp: IMAGES.nationalWebp,
            small: IMAGES.nationalSmall,
            smallWebp: IMAGES.nationalSmallWebp,
        },
        card: {
            jpg: IMAGES.nationalCard,
            webp: IMAGES.nationalCardWebp,
            pack: IMAGES.nationalCardPack,
        },
        planFeatures: ['directBookFlights', 'bookStandardHotel', 'includesGuideLong', 'amazingAdventure'],
        order: 4,
    },
    {
        id: 9,
        active: false,
        slug: 'aloy',
        title: 'chooseYourDestination',
        subtitle: '',
        summary: '',
        colors: {
            default: '#4599fc',
            light: '#4599fc',
            dark: '#1e33c0',
        },
        planIcon: IMAGES.aloyPlanIcon,
        cover: {
            large: IMAGES.explore,
            largeWebp: IMAGES.exploreWebp,
            small: IMAGES.exploreSmall,
            smallWebp: IMAGES.exploreSmallWebp,
        },
        card: {
            jpg: '',
            webp: '',
            pack: '',
        },
        planFeatures: [''],
        order: 100,
    },
    {
        id: 10,
        active: true,
        slug: 'christmas_markets',
        title: 'christmasMarkets',
        subtitle: 'coverInfoTextChristmas',
        summary: 'christmasShortSummary',
        colors: {
            default: '#ffffff',
            light: '#1C9C5A',
            dark: '#FD383B',
        },
        planIcon: IMAGES.christmasPlanIcon,
        cover: {
            large: IMAGES.christmas,
            largeWebp: IMAGES.christmasWebp,
            small: IMAGES.christmasSmall,
            smallWebp: IMAGES.christmasSmall,
        },
        card: {
            jpg: '',
            webp: '',
            pack: '',
        },
        planFeatures: ['nearLodging', 'getawayRoute', 'amazingAdventure'],
        order: 100,
    },
];

export default plans;
