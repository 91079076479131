import { Link } from 'react-router-dom';
import logo from '@/assets/icons/logoFlyKube.png';
import styles from './Header.module.scss';

const DummyHeader = () => {
    return (
        <header>
            <div className={styles.headerContainer}>
                <div className={styles.headerLeft}>
                    <Link className={styles.logoContainer} to="/" aria-label="home">
                        <img src={logo} className={styles.logo} alt="" />
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default DummyHeader;
