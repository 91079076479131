/* eslint-disable */
let initialized = false;
let debug = false;

/**
 * Warn
 * @param  {...any} args
 */
const warn = (...args) => {
    if (!debug) {
        return;
    }
    // eslint-disable-next-line no-console
    console.info(...['[share-a-sale-pixel]'].concat(args));
};

/**
 * Log
 * @param  {...any} args
 */
const log = (...args) => {
    if (!debug) {
        return;
    }
    // eslint-disable-next-line no-console
    console.info(...['[share-a-sale-pixel]'].concat(args));
};

const verifyInit = () => {
    if (!initialized) {
        warn('Pixel not initialized before using call CustomPixel.init with required params');
    }
    return initialized;
};

//
const defaultOptions = {
    debug: false,
};

//
const ShareASalePixel = {
    init(masterTagId, options = defaultOptions) {
        if (initialized) {
            warn('Pixel script already initialized');
            return;
        }

        if (typeof masterTagId !== 'string' || masterTagId.trim() === '') {
            throw new Error('masterTagId is missing');
        }

        // Check if the script is already loaded
        const scriptId = 'share-a-sale-pixel-script';
        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = `https://www.dwin1.com/${masterTagId}.js`;
            script.type = 'text/javascript';
            script.defer = true;
            document.body.appendChild(script);
        }

        initialized = true;
        debug = options.debug;

        if (debug) {
            log('Custom pixel script initialized');
        }
    },

    remove() {
        const scriptId = 'share-a-sale-pixel-script';
        const script = document.getElementById(scriptId);
        if (script) {
            document.body.removeChild(script);
            initialized = false;

            if (debug) {
                log('Custom pixel script removed');
            }
        } else {
            if (debug) {
                warn('Pixel script not found');
            }
        }
    },

    trackPurchase(amount, orderRef, currency, voucher = '', test = '0') {
        // Asegurarse de que AWIN está definido
        var AWIN = (window.AWIN = window.AWIN || {});
        AWIN.Tracking = AWIN.Tracking || {};
        AWIN.Tracking.Sale = {};

        // Configurar los parámetros de la transacción
        AWIN.Tracking.Sale.amount = `${Number(amount).toFixed(2)}`;
        AWIN.Tracking.Sale.channel = 'aw';
        AWIN.Tracking.Sale.orderRef = `${orderRef}`;
        AWIN.Tracking.Sale.parts = `DEFAULT:${amount}`;
        AWIN.Tracking.Sale.currency = `${currency}`;
        AWIN.Tracking.Sale.voucher = `${voucher}`;
        AWIN.Tracking.Sale.test = `${test}`;

        if (debug) {
            log('Purchase tracked: ', AWIN.Tracking.Sale);
        }
    },
};

export default ShareASalePixel;
